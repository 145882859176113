import { Profile } from "../../Utility/Variables/Types";

const getProfile = async (token: string): Promise<Profile | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Realty/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    // use FE Logger when set up
    return null;
  }
};

export default getProfile;
