import { useMemo } from "react";
import { FormData } from "../Utility/Variables/Interfaces";

const useProjectToAdd = (formData: Partial<FormData>) => {
  // Dependency on formData
  return useMemo(
    () => ({
      id: formData.id || "default",
      realtyId: formData.realtyId || "unnamedRealty",
      name: formData.campaignTitle || "unnamedProject",
      campaign: {
        headline: formData.headlineText || "",
        description: formData.descriptionText || "",
        callToAction: formData.callToActionText || "",
        targetAudience: formData.targetAudienceText || "",
        category: "RealEstate", // Hard coded for now as RealEstate is the only option for MVP in the BE
        mediaSize: formData.mediaSize || [{ width: 1024, height: 1024 }],
        images: formData.images || [],
        generationImages: formData.generationImages || [],
      },
    }),
    [formData],
  );
};

export default useProjectToAdd;
