import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import { LoginProvider } from "./Context/LoginContext";
import { ProjectProvider } from "./Context/ProjectsContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    authorizationParams={{
      redirect_uri: `${process.env.REACT_APP_SELLSTATICFE_URL}`,
      audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
    }}
  >
    <React.StrictMode>
      <Router>
        <LoginProvider>
          <ProjectProvider>
            <App />
          </ProjectProvider>
        </LoginProvider>
      </Router>
    </React.StrictMode>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
