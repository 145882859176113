const steps = [
  {
    selector: ".realty-realtor-name",
    content: "Enter the name of your Realty or Realtor here.",
  },
  {
    selector: ".logo-upload",
    content:
      "Upload the logo for your Realty or Realtor here by dragging and dropping or clicking to select files.",
  },
  {
    selector: ".description",
    content:
      "Provide a description of your realty services here. You can use AI to enhance your description.",
  },
  {
    selector: ".preview",
    content:
      "Preview your branding materials to see how it will look to your clients.",
  },
  {
    selector: ".brand-colors",
    content: "Select your brand colors to be displayed on your profile.",
  },
  {
    selector: ".font-selection",
    content: "Choose the desired font for your profile.",
  },
  {
    selector: ".contact-information",
    content:
      "Enter your contact information including email and phone numbers.",
  },
  {
    selector: ".address",
    content:
      "Provide your office address. Use the map to pinpoint your location accurately.",
  },
  {
    selector: ".social-media-profiles",
    content:
      "Add links to your social media profiles to connect with your clients.",
  },
  {
    selector: ".step-1",
    content:
      "This is the Automated Marketing section. Here you can create a new ad campaign and automate your marketing.",
  },
  {
    selector: ".step-2",
    content: "Click here to create a new ad campaign.",
  },
  {
    selector: ".step-3",
    content: "Enter the title of your campaign here.",
  },
  {
    selector: ".step-4",
    content: "Select the applicable media display size for your campaign.",
  },
  {
    selector: ".step-5",
    content: "Click here to go to the next step.",
  },
  {
    selector: ".step-6",
    content: "Give your campaign a creative title in the headline section.",
  },
  {
    selector: ".step-7",
    content: "Describe what your campaign is about in the description section.",
  },
  {
    selector: ".step-8",
    content: "Define your target audience here.",
  },
  {
    selector: ".step-9",
    content: "Select the type of ad from the dropdown menu.",
  },
  {
    selector: ".step-10",
    content: "Click here to go to the next step.",
  },
  {
    selector: ".step-11",
    content: "Add images or use AI generated images and call to action",
  },
  {
    selector: ".step-12",
    content: "Click here to generate your ad.",
  },
  {
    selector: ".workflow4-intro",
    content:
      "Welcome to the Marketing Ads section. Here you can manage your ads.",
  },
  {
    selector: ".workflow4-select-platforms",
    content:
      "Select the Marketing Ads you wish to post on Facebook, Instagram, and Twitter.",
  },
  {
    selector: ".workflow4-download-email",
    content: "You can also choose to download or email your marketing ads.",
  },
  {
    selector: ".workflow4-choose-templates",
    content: "Choose your desired templates for the ads.",
  },
  {
    selector: ".message-sellstatic-ai",
    content: "This is where you can message SellStatic AI.",
  },
  {
    selector: ".search-sellstatic-ai",
    content: "Feel free to ask any questions or request assistance.",
  },
];

export default steps;
