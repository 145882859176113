import React from "react";

// TODO: move to env var
const SendMessage = async (
  userMessage: string,
  setMessages: React.Dispatch<
    React.SetStateAction<{ user: string; text: string }[]>
  >,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  setIsLoading(true);

  try {
    // Update UI first
    setMessages((prev) => [...prev, { user: "user", text: userMessage }]);

    // Send message to OpenAI API
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo", // or the appropriate model
        messages: [{ role: "user", content: userMessage }],
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch from OpenAI API");
    }

    const data = await response.json();

    // Check if the response contains the expected content
    if (!data.choices[0].message.content) {
      throw new Error("No valid response from the API");
    }

    // Add the AI's response to the UI
    setMessages((prev) => [
      ...prev,
      { user: "bot", text: data.choices[0].message.content },
    ]);
  } catch (error) {
    console.error("Error sending message:", error);
    setMessages((prev) => [
      ...prev,
      {
        user: "bot",
        text: "I apologize. Unfortunately SellStatic AI is currently under going maintenance. At this time we are unable to provide any responses to your questions.",
      },
    ]);
  } finally {
    setIsLoading(false);
  }
};
export default SendMessage;
