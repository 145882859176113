import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTour } from "@reactour/tour";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormContext } from "../../Context/FormContext";
import { useNavbarContext } from "../../Context/NavBarContext";
import { useLogin } from "../../Context/LoginContext";
import {
  initializeMap,
  handleAddressSearchOnPopulate,
  handleAddressSearch,
  handleSuggestionSelect,
  handleAddressChange,
} from "../../Utility/Modules/MapBox";
import {
  handlePrimaryColorChange,
  handleSecondaryColorChange,
  handleTertiaryColorChange,
  handleBrandingMaterials,
  handleDeleteBM,
  descriptionTextChange,
  saveProfile,
  updateProfile,
  enhanceText,
} from "../../Utility/Modules/ProfileModule";
import {
  MAX_DESCRIPTION_CHAR_CNT_MD,
  PHONE_SIZE_CONSTANT,
  MAX_WORDS_DESCRIPTION,
  FONTS,
} from "../../Utility/Variables/Constants";
import { Suggestion } from "../../Utility/Variables/Interfaces";
import "mapbox-gl/dist/mapbox-gl.css";

import useProfileData from "../../Hooks/ProfileData";
import ImageDropArea from "../../Components/ImageDrop/ImageDrop";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import FontDropdown from "../../Components/FontDropdown/FontDropdown";

import PopulateProfile from "../../Utility/Functions/PopulateProfile";
import PutProfile from "../../Services/Profile/PutProfile";
import GetImages from "../../Services/S3/GetImages";
import DeleteImages from "../../Services/S3/DeleteImages";

import Square from "../../Images/Profile/SquareIcon.png";
import MailIcon from "../../Images/Profile/MailIcon.png";
import PhoneIcon from "../../Images/Profile/PhoneIcon.png";
import FacebookIcon from "../../Images/Common/FacebookIcon.png";
import InstagramIcon from "../../Images/Common/InstagramIcon.png";
import TwitterIcon from "../../Images/Common/TwitterIcon.png";
import LinkedinIcon from "../../Images/Common/LinkedinIcon.png";

import removeBackground from "../../Services/Photoroom/RemoveBackground";

mapboxgl.accessToken = String(process.env.REACT_APP_MAPBOX_TOKEN);

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { setIsOpen, setCurrentStep } = useTour();
  const startTour = () => {
    setCurrentStep(0);
    navigate("/profile", { replace: true });
    setIsOpen(true);
  };

  // Destruct necessary variables, functions and utilities
  const { getAccessTokenSilently } = useAuth0(); // Access token for BE validation
  const { isSidebarExpanded } = useNavbarContext();
  const { formData, updateFormData } = useFormContext();
  const { isProfileEmpty, profileData, setProfileData } = useLogin();
  // Map box states & references
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null); // Reference to the map container div
  // Branding colour states
  const [primaryColor, setPrimaryColor] = useState("#6b7280"); // default gray
  const [secondaryColor, setSecondaryColor] = useState("#8b5cf6"); // default purple
  const [tertiaryColor, setTertiaryColor] = useState("#475569"); // default slate
  // Text states & references
  const [descriptionWordCount, setDescriptionWordCount] = useState(0);
  const [descriptionOverflow, setDescriptionOverflow] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  /** **** Very Important to also update apiData default value to match!! ****** */
  const [selectedFont, setSelectedFont] = useState("Source Sans Pro");
  // Image states
  const [logo, setLogo] = useState<File>();
  const [preview, setPreview] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // Dialog for background removal
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [brandingMaterials, setBrandingMaterials] = useState<File[]>([]);
  const [fileOverflow, setFileOverflow] = useState(true); // Branding materials file overflow
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // used to deleting brand materials
  const [addMargin, setMargin] = useState(false); // add margin to branding materials
  const [emailError, setEmailError] = useState<string | null>(null);
  const [realtyNameError, setRealtyNameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [workphoneError, setWorkPhoneError] = useState<string | null>(null);

  // Mobile states
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < PHONE_SIZE_CONSTANT,
  );
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 400);
  // Loading & apiData States
  const [isLoading, setIsLoading] = useState(false);
  const apiData = useProfileData(formData);
  // Used for the very first time a user signed up to handle the case where save is pressed multiple times during initial login
  let initialLogin = 0;
  // special case constant to combine branding materials pulled from the db, and newly uploaded/updated branding materials
  const combinedBrandMaterials = [
    ...(brandingMaterials
      ? brandingMaterials.map((file, index) => ({
          url: URL.createObjectURL(file),
          type: file.type,
          source: "localFiles",
          originalIndex: index, // Keep track of the original index in brandingMaterials
        }))
      : []),
    ...(formData.brandFiles
      ? formData.brandFiles.map((url, index) => ({
          url,
          type: "image/",
          source: "s3Urls",
          originalIndex: index, // Keep track of the original index in brandFiles
        }))
      : []),
  ];

  // Initialization, empty dependency effects
  useEffect(() => {
    // Check if map is not already initialized
    if (!map && mapContainerRef.current) {
      initializeMap(mapContainerRef, setMap);
    }
  }, []); // Initialize the map once on first render

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        mapContainerRef.current &&
        !mapContainerRef.current.contains(e.target as Node)
      ) {
        // Clicked outside the "mapContainer" div
        setSuggestions([]); // Close the suggestions dropdown
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      setIsMobile(innerWidth < PHONE_SIZE_CONSTANT);
      setIsSmallMobile(innerWidth < 400); // Adjust this constant as needed
    };

    // Call handleResize initially and also on resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Run once on mount

  const handleTextOverflow = (isOverflowing: boolean) => {
    // Handle text overflow logic here
    if (isOverflowing) {
      setDescriptionOverflow(true);
    } else {
      // Reset scroll and overflow state
      if (descriptionRef.current) {
        descriptionRef.current.scrollTop = 0;
      }
      setDescriptionOverflow(false);
    }
  };

  // On mount and unmount, dependent effects
  // Populate form data with the profile info
  useEffect(() => {
    const populateProfile = async () => {
      try {
        await PopulateProfile(
          profileData,
          MAX_DESCRIPTION_CHAR_CNT_MD,
          updateFormData,
          handleTextOverflow,
        );
        setPrimaryColor(profileData.colors.primary);
        setSecondaryColor(profileData.colors.secondary);
        setTertiaryColor(profileData.colors.tertiary);

        const profileAddress = `${profileData.realtor.address.unitNumber} ${profileData.realtor.address.streetName} ${profileData.realtor.address.city} ${profileData.realtor.address.province} ${profileData.realtor.address.zipCode} ${profileData.realtor.address.country}`;
        setAddress(profileAddress);
        handleAddressSearchOnPopulate(profileAddress, map);

        if (profileData.brandLogo) {
          setImagePreviewUrl(profileData.brandLogo);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    populateProfile().then(() => {
      /* Use FE Logger when set up */
    });
  }, [map, profileData]); // Run whenever profile data is updated (should update onSave and image delete)

  useEffect(() => {
    // Calculate the new word count
    const newWordCount = (formData.realtyDescription ?? "")
      .split(/\s+/)
      .filter(Boolean).length;
    setDescriptionWordCount(newWordCount);

    // Check if the new description length exceeds your character count limit
    const isOverflowing =
      (formData.realtyDescription ?? "").length > MAX_DESCRIPTION_CHAR_CNT_MD;
    setDescriptionOverflow(isOverflowing);
  }, [formData.realtyDescription]);

  useEffect(() => {
    if (map && mapContainerRef.current) {
      map.resize();
    }
  }, [map, isSidebarExpanded]); // Map is rendered and sidebar state changes

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phone: string) => {
    const regex = /^\+?[1-9]\d{9,14}$/; // Basic validation for international E.164 format
    return regex.test(phone);
  };

  const validateWorkPhoneNumber = (workPhone: string) => {
    const regex = /^\+?[1-9]\d{9,14}$/; // Basic validation for international E.164 format
    return regex.test(workPhone);
  };
  // Profile top level functions
  const onSave = async () => {
    let hasError = false;

    if (!formData.realtyName || formData.realtyName.trim() === "") {
      setRealtyNameError("Realty name cannot be empty");
      hasError = true;
    }

    if (!formData.email || !validateEmail(formData.email)) {
      setEmailError("Invalid email address");
      hasError = true;
    }

    if (!formData.phone || !validatePhoneNumber(String(formData.phone))) {
      setPhoneError("Invalid phone number");
      hasError = true;
    }

    if (
      !formData.workPhone ||
      !validatePhoneNumber(String(formData.workPhone))
    ) {
      setWorkPhoneError("Invalid phone number");
      hasError = true;
    }

    if (!imagePreviewUrl) {
      setFileOverflow(false);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const realtorName = formData.realtyName || "";

    if (realtorName.trim() === "") {
      setRealtyNameError("Realty name cannot be empty");
      return;
    }

    setRealtyNameError(null); // Clear error if name is valid

    setIsLoading(true);
    const key = String(formData.userId);
    let type = "PUT";
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    if (isProfileEmpty && initialLogin === 0) {
      type = "POST";
      initialLogin++; // Increment so that POST is not used again on First time customer Login IN
    }

    await saveProfile(key, token, logo, brandingMaterials, type, apiData);
    await updateProfile(token, setBrandingMaterials, setProfileData);

    setIsLoading(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    updateFormData({ email });
    if (validateEmail(email)) {
      setEmailError(null);
    } else {
      setEmailError("Invalid email address");
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value;
    updateFormData({ phone });
    if (validatePhoneNumber(phone)) {
      setPhoneError(null);
    } else {
      setPhoneError("Invalid phone number");
    }
  };

  const handleWorkPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const workPhone = e.target.value;
    updateFormData({ workPhone });
    if (validateWorkPhoneNumber(workPhone)) {
      setWorkPhoneError(null);
    } else {
      setWorkPhoneError("Invalid phone number");
    }
  };

  const handleLogoUploaded = (files: File[]) => {
    if (files.length > 0 && files[0].type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(files[0]);
      setImagePreviewUrl(imageUrl);
      setLogo(files[0]);
      setOpenDialog(true);
    }
  };

  const handleBackgroundRemoval = async (shouldRemoveBackground: boolean) => {
    setOpenDialog(false);
    if (shouldRemoveBackground && logo) {
      try {
        const processedBlob = await removeBackground(logo);
        const updatedImageUrl = URL.createObjectURL(processedBlob);
        setImagePreviewUrl(updatedImageUrl);
        setLogo(new File([processedBlob], logo.name, { type: logo.type }));
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const deleteImages = async (index: number) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    // Delete the images in S3
    await DeleteImages(index, token);
    // Update API data
    apiData.brandFiles = await GetImages("Branding", token);
    // Update database
    await PutProfile(apiData, token)
      .then(() => {
        /* Use FE logs when set up */
      })
      .catch(() => {
        /* Use FE logs when set up */
      });
    // Update UI profile
    await updateProfile(token, setBrandingMaterials, setProfileData);
  };

  const renderBrandMaterials = combinedBrandMaterials.map((item, index) => {
    return (
      <div
        key={index}
        className={`relative rounded-lg max-h-[100%] sm:h-full w-full flex items-center justify-center ${
          hoveredIndex === index ? "bg-gray-200 bg-opacity-50" : ""
        }`}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        {hoveredIndex === index && (
          <button
            className="absolute top-2 right-2 bg-red-500 text-white rounded-lg px-2 py-1"
            onClick={() =>
              handleDeleteBM(
                index,
                combinedBrandMaterials,
                brandingMaterials,
                setMargin,
                setBrandingMaterials,
                updateFormData,
                deleteImages,
                formData,
              )
            }
          >
            Delete
          </button>
        )}
        {item.type.startsWith("image/") ? (
          <img
            src={item.url}
            alt="Uploaded"
            className="max-w-full max-h-full"
          />
        ) : (
          <div className="p-2 max-w-full max-h-full">
            <p className="break-words overflow-hidden">{item.url}</p>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="p-4 flex justify-between items-center">
        <div className="px-2 pb-2">
          <header className="text-black text-[30px] tracking-tight font-medium font-['Montserrat']">
            Profile
          </header>
          <header className="text-black mt-2 sm:mt-0 text-base font-montserrat tracking-tight">
            See your different projects as well as create new ones under the
            realty
          </header>
        </div>

        <div className="flex items-center justify-center p-2 space-x-4">
          <button
            className="px-4 py-2 bg-custom-select text-white rounded-[10px] text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
            onClick={startTour}
          >
            Start Tour
          </button>
          {!isLoading ? (
            <button
              onClick={onSave}
              className="px-4 py-2 bg-custom-select rounded-[10px] text-white text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
            >
              Save
            </button>
          ) : (
            <button
              disabled
              className="px-4 py-2 bg-custom-select rounded-[10px] text-white text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 mb-1 h-4 me-3 text-gray-200 animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#9f7aea"
                />
              </svg>
              Saving...
            </button>
          )}
        </div>
      </div>

      <div className="h-full px-4 pb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {/* Col 1 */}
        <div className=" flex flex-col">
          {/* Row 1 */}

          <div className=" h-[8%] flex items-center space-x-2 bg-white profile-step border-2 rounded-lg p-4 shadow-md mb-4 realty-realtor-name">
            <span className="font-semibold px-1">Realty/Realtor Name</span>

            <input
              type="text"
              value={formData.realtyName || ""}
              onChange={(e) => {
                updateFormData({ realtyName: e.target.value });
                if (e.target.value.trim() === "") {
                  setRealtyNameError("Realty name cannot be empty");
                } else {
                  setRealtyNameError(null);
                }
              }}
              placeholder="name..."
              className="flex-1 rounded-lg py-1 px-2 text-sm outline-none border border-black"
            />
            {realtyNameError && (
              <p className="text-red-600 text-xs">{realtyNameError}</p>
            )}
          </div>

          {/* Row 2 */}

          <div className="h-[28%] flex items-center p-4 bg-white border rounded-lg shadow-md mb-4 logo-upload">
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              aria-labelledby="background-removal-dialog-title"
              aria-describedby="background-removal-dialog-description"
            >
              <DialogTitle id="background-removal-dialog-title">
                Remove Background
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="background-removal-dialog-description">
                  Would you like to remove the background from this image?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleBackgroundRemoval(false)}
                  color="primary"
                >
                  No
                </Button>
                <Button
                  onClick={() => handleBackgroundRemoval(true)}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            <div
              className={`flex w-[45%] sm:w-[28%] h-full items-center justify-center rounded-lg mr-4 overflow-hidden ${
                imagePreviewUrl ? "border-none" : "border border-purple-600"
              }`}
            >
              {imagePreviewUrl ? (
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  className="object-contain w-full h-full"
                />
              ) : (
                <p className="text-gray-700 text-center">
                  Custom <br /> Logo
                </p>
              )}
            </div>
            <ImageDropArea
              onFilesUploaded={handleLogoUploaded}
              isMobile={isMobile}
            />
          </div>

          {/* Row 3 */}

          <div className="h-[32%] bg-white border p-4 rounded-lg shadow-md flex flex-col space-y-4 mb-4">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-lg font-semibold font-montserrat text-gray-900 description">
                  Description
                </h2>
                <p
                  className="text-[14px] sm:text-[16px] w-[90%] sm:w-full text-gray-500 font-montserrat"
                  data-tour="profile-step-2"
                >
                  Here is where you can describe your realty.
                </p>
              </div>

              {!isEnhancing ? (
                <button
                  onClick={() =>
                    enhanceText(
                      formData,
                      updateFormData,
                      setIsEnhancing,
                      handleTextOverflow,
                    )
                  }
                  // <button onClick={onHeadlineEnhance}
                  className="min-w-[40%] text-[15px] sm:text-[18px] mt-4 sm:mt-0 sm:min-w-fit text-white bg-purple-500 hover:bg-purple-600 px-4 py-2 rounded-md"
                >
                  Enhance with AI
                </button>
              ) : (
                <button
                  disabled
                  type="button"
                  className="min-w-[40%] sm:min-w-fit text-[15px] sm:text-[18px] mt-4 sm:mt-0 flex justify-center py-2 px-4 font-medium bg-purple-500 text-violet-100 rounded-md border border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#9f7aea"
                    />
                  </svg>
                  Enhancing...
                </button>
              )}
            </div>

            <div className="space-y-3 flex-1 flex flex-col justify-center items-center">
              <textarea
                ref={descriptionRef}
                value={formData.realtyDescription || ""}
                onChange={(event) =>
                  descriptionTextChange(
                    event,
                    updateFormData,
                    setDescriptionWordCount,
                    descriptionRef,
                    setDescriptionOverflow,
                  )
                }
                className={`px-2 pt-1 h-full resize-none form-textarea mt-1 text-[14px] sm:text-[16px] block w-full rounded-md border border-black shadow-sm focus:outline-none ${
                  descriptionOverflow ? "overflow-y-auto" : "overflow-y-hidden"
                }`}
                rows={4}
                placeholder="Define what you do, where you are located, who you can help, what makes you unique..."
              />
              {/* Word count display */}
              <div className="text-right w-full text-sm">
                {descriptionWordCount}/{MAX_WORDS_DESCRIPTION}
              </div>
            </div>
          </div>

          {/* Row 4 */}

          <div
            className={`h-[32%] p-4 ${
              addMargin ? "sm:mb-4" : "sm:mb-0"
            } bg-white rounded-lg shadow-md flex flex-col space-y-4`}
          >
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold text-gray-900 preview">
                Preview of branding materials
              </h2>
              <img
                src={Square}
                alt="Custom Logo"
                className="size-5"
                onClick={() => setPreview(!preview)}
              />
            </div>

            <div
              className={`flex items-center h-40 justify-center ${
                fileOverflow ? "overflow-auto" : ""
              }`}
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 auto-rows-[150px] sm:auto-rows-[100%] justify-center h-full gap-4">
                <DragAndDrop
                  onFilesUploaded={(Files) =>
                    handleBrandingMaterials(
                      Files,
                      brandingMaterials,
                      setFileOverflow,
                      isMobile,
                      setBrandingMaterials,
                      setMargin,
                    )
                  }
                  isMobile={isMobile}
                />

                {renderBrandMaterials}
              </div>
            </div>
          </div>
        </div>

        {/* Col 2 */}
        <div className="flex flex-col">
          {/* Row 1 */}

          <div className="h-[30%] sm:h-[36%] p-4 bg-white rounded-lg shadow-md mb-4 flex flex-col justify-center">
            <h2 className="text-2xl font-semibold text-gray-700 text-left ml-2 sm:ml-10 mb-4 brand-colors">
              Brand Colors
            </h2>
            <div className="flex justify-evenly items-center p-2 sm:p-4 rounded-lg">
              <div className="text-center relative">
                <input
                  type="color"
                  id="primaryColorInput"
                  value={primaryColor}
                  onChange={(event) =>
                    handlePrimaryColorChange(
                      event,
                      updateFormData,
                      setPrimaryColor,
                    )
                  }
                  className="absolute w-6 h-6 opacity-0" // make it small and invisible
                  style={{
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: `${isMobile ? "180%" : "-90%"}`,
                    marginLeft: "1rem",
                  }} // position it next to the color block
                />
                <div
                  className={`${
                    isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                  } rounded-lg shadow-lg cursor-pointer`}
                  style={{ backgroundColor: primaryColor }}
                  onClick={() =>
                    document.getElementById("primaryColorInput")?.click()
                  }
                >
                  {/* The color block */}
                </div>
                <p className="mt-2 text-sm font-medium text-gray-600">
                  Primary
                </p>
              </div>

              <div className="text-center relative">
                <input
                  type="color"
                  id="secondaryColorInput"
                  value={secondaryColor}
                  onChange={(event) =>
                    handleSecondaryColorChange(
                      event,
                      updateFormData,
                      setSecondaryColor,
                    )
                  }
                  className="absolute w-6 h-6 opacity-0" // make it small and invisible
                  style={{
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: `${isMobile ? "130%" : "-90%"}`,
                    marginLeft: "1rem",
                  }} // position it next to the color block
                />
                <div
                  className={`${
                    isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                  } rounded-lg shadow-lg cursor-pointer`}
                  style={{ backgroundColor: secondaryColor }}
                  onClick={() =>
                    document.getElementById("secondaryColorInput")?.click()
                  }
                >
                  {/* The color block */}
                </div>
                <p className="mt-2 text-sm font-medium text-gray-600">
                  Secondary
                </p>
              </div>

              <div className="text-center relative">
                <input
                  type="color"
                  id="tertiaryColorInput"
                  value={tertiaryColor}
                  onChange={(event) =>
                    handleTertiaryColorChange(
                      event,
                      updateFormData,
                      setTertiaryColor,
                    )
                  }
                  className="absolute w-6 h-6 opacity-0" // Make the color input invisible and position it next to the color block
                  style={{
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: `${isMobile ? "82%" : "-90%"}`,
                    marginLeft: "1rem",
                  }} // Adjust position as needed
                />
                <div
                  className={`${
                    isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                  } rounded-lg shadow-lg cursor-pointer`}
                  style={{ backgroundColor: tertiaryColor }}
                  onClick={() =>
                    document.getElementById("tertiaryColorInput")?.click()
                  }
                >
                  {/* The color block */}
                </div>
                <p className="mt-2 text-sm font-medium text-gray-600">
                  Tertiary
                </p>
              </div>
            </div>
          </div>

          {/* Row 2 */}

          <div className="h-[8%] p-4 bg-white rounded-lg shadow-md flex items-center space-x-2 mb-4">
            <p className="block text-sm font-medium text-gray-700">
              Choose desired font
            </p>
            <div className="flex-grow font-selection">
              <FontDropdown
                fonts={FONTS}
                selectedFont={selectedFont}
                onFontChange={setSelectedFont}
              />
            </div>
          </div>

          {/* Row 3 */}

          <div className="h-auto sm:h-auto flex justify-around sm:space-x-4 mb-4">
            {/* Contact Information Card */}
            <div className="hidden sm:visible sm:p-2 sm:flex sm:flex-col bg-white rounded-lg shadow-md">
              <h2 className="text-lg font-semibold text-gray-900 mb-4 contact-information">
                Contact information
              </h2>

              <div className="space-y-3 flex-1 flex flex-col justify-center items-center">
                <div className="flex items-center space-x-2">
                  <img src={MailIcon} alt="Email" className="h-4 w-5" />
                  <input
                    type="email"
                    value={formData.email || ""}
                    onChange={handleEmailChange}
                    className="form-input block w-full pl-2 border border-gray-300 rounded-md"
                    placeholder="Email Address"
                  />
                </div>
                {emailError && (
                  <p className="self-start text-red-600 text-xs ml-7">
                    {emailError}
                  </p>
                )}
                <div className="flex items-center space-x-2">
                  <img src={PhoneIcon} alt="Phone" className="h-5 w-5" />
                  <input
                    type="tel"
                    value={formData.phone || ""}
                    onChange={handlePhoneChange}
                    className="form-input block w-full pl-2 border border-gray-300 rounded-md"
                    placeholder="Personal Cell"
                  />
                </div>
                {phoneError && (
                  <p className="self-start text-red-600 text-xs ml-7">
                    {phoneError}
                  </p>
                )}
                <div className="flex items-center space-x-2">
                  <img src={PhoneIcon} alt="Phone" className="h-5 w-5" />
                  <input
                    type="tel"
                    value={formData.workPhone || ""}
                    onChange={handleWorkPhoneChange}
                    className="form-input block w-full pl-2 border border-gray-300 rounded-md"
                    placeholder="Work Cell"
                  />
                </div>
                {workphoneError && (
                  <p className="self-start text-red-600 text-xs ml-7">
                    {workphoneError}
                  </p>
                )}
              </div>
            </div>

            {
              /* Address Card */
              // Fix styling and click our of dropdown address select
            }
            <div className="bg-white rounded-lg shadow-md p-2 address flex flex-col w-full sm:w-[60%] 2xl:w-[70%]">
              <div className="relative flex flex-row">
                <h2 className="text-lg font-semibold text-gray-900 self-start">
                  Address:
                </h2>
                <input
                  type="text"
                  id="address-search"
                  value={address}
                  onChange={(event) =>
                    handleAddressChange(
                      event,
                      setAddress,
                      setSuggestions,
                      suggestionSelected,
                      setSuggestionSelected,
                    )
                  }
                  className=" w-full pl-3 pr-3 pb-2 pt-1 border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search address..."
                />
                {suggestions.length > 0 && (
                  <ul className="absolute z-10 w-full bg-white max-h-58 sm:max-h-60 overflow-auto border border-gray-300 rounded-md top-full">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() =>
                          handleSuggestionSelect(
                            suggestion,
                            map,
                            setAddress,
                            setSuggestions,
                            setSuggestionSelected,
                            updateFormData,
                          )
                        }
                      >
                        {suggestion.place_name}
                      </li>
                    ))}
                  </ul>
                )}
                <button
                  onClick={() =>
                    handleAddressSearch(address, map, updateFormData)
                  }
                  className="text-white bg-purple-500 hover:bg-purple-600 rounded-md text-sm px-2 py-px self-start"
                >
                  Search
                </button>
              </div>

              <div
                id="mapContainer"
                ref={mapContainerRef}
                className="sm:mt-2 blur-0 rounded-lg w-full h-full"
              />
            </div>
          </div>

          {/* Row 4 */}

          <div
            className={`h-[22%] ${
              addMargin ? "sm:mb-4" : "sm:mb-0"
            } flex justify-around space-x-4 sm:space-x-0 mb-4 sm:mb-0`}
          >
            {/* Contact Information Card */}
            <div className="visible sm:hidden sm:p-2 flex flex-col bg-white rounded-lg shadow-md">
              <h2 className="text-lg font-semibold text-gray-900 p-2 pl-3">
                Contact information
              </h2>

              <div className="space-y-3 ml-2 flex-1 w-[90%] flex flex-col justify-center items-center">
                <div className="flex items-center space-x-2">
                  <img src={MailIcon} alt="Email" className="h-4 w-5" />
                  <input
                    type="email"
                    value={formData.email || ""}
                    onChange={handleEmailChange}
                    className="block w-full pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Email Address"
                  />
                  {emailError && (
                    <p className="text-red-600 text-xs">{emailError}</p>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <img src={PhoneIcon} alt="Phone" className="h-5 w-5" />
                  <input
                    type="tel"
                    value={formData.phone || ""}
                    onChange={handlePhoneChange}
                    className="block w-full pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Personal Cell"
                  />
                </div>
                {phoneError && (
                  <p className="self-start text-red-600 text-xs ml-7">
                    {phoneError}
                  </p>
                )}
                <div className="flex items-center space-x-2">
                  <img src={PhoneIcon} alt="Phone" className="h-5 w-5" />
                  <input
                    type="tel"
                    value={formData.workPhone || ""}
                    onChange={handleWorkPhoneChange}
                    className="block w-full pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Work Cell"
                  />
                  {workphoneError && (
                    <p className="text-red-600 text-xs ">{workphoneError}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Social Media */}
            <div className="sm:w-full sm:h-full w-1/2 bg-white rounded-lg shadow-md pl-3 p-2 sm:p-3">
              <h2 className="text-lg font-semibold text-gray-900 mb-1 sm:mb-3 social-media-profiles">
                Social Media Profiles
              </h2>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:justify-center">
                <div className="flex items-center w-[45%] justify-end sm:px-4 sm:py-1 rounded-lg">
                  {/* Facebook Icon */}
                  <img
                    src={FacebookIcon}
                    alt="Custom Logo"
                    className="size-9 sm:size-6"
                  />
                  <input
                    value={formData.facebook || ""}
                    onChange={(e) =>
                      updateFormData({ facebook: e.target.value })
                    }
                    className="form-input block w-full ml-2 pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Facebook link..."
                  />
                </div>

                {/* Instagram Button */}
                <div className="flex items-center w-[45%] justify-start sm:px-4 sm:py-1 rounded-lg">
                  {/* Instagram Icon */}
                  <img
                    src={InstagramIcon}
                    alt="Custom Logo"
                    className="size-9 sm:size-6"
                  />
                  <input
                    value={formData.instagram || ""}
                    onChange={(e) =>
                      updateFormData({ instagram: e.target.value })
                    }
                    className="form-input block w-full ml-2 pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Instagram link..."
                  />
                </div>

                {/* Twitter Button */}
                <div className="flex items-center w-[45%] justify-end sm:justify-center sm:px-4 sm:py-1 rounded-lg">
                  {/* Twitter Icon */}
                  <img
                    src={TwitterIcon}
                    alt="Custom Logo"
                    className="size-9 sm:size-6"
                  />
                  <input
                    value={formData.twitter || ""}
                    onChange={(e) =>
                      updateFormData({ twitter: e.target.value })
                    }
                    className="form-input block w-full ml-2 pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Twitter link..."
                  />
                </div>

                {/* LinkedIn Button */}
                <div className="flex items-start w-[45%] justify-start sm:px-4 sm:py-1 rounded-lg">
                  {/* LinkedIn Icon */}
                  <img
                    src={LinkedinIcon}
                    alt="Custom Logo"
                    className="size-9 sm:size-6"
                  />
                  <input
                    value={formData.linkedIn || ""}
                    onChange={(e) =>
                      updateFormData({ linkedIn: e.target.value })
                    }
                    className="form-input block w-full ml-2 pl-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="LinkedIn link..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {preview && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative bg-white p-4 flex flex-col rounded-lg max-h-[98%] max-w-[96%] sm:max-w-[80%]">
              <button
                className="ml-auto top-2 right-2 bg-custom-select text-white px-2 py-1 mb-4 rounded"
                onClick={() => {
                  setPreview(false);
                }}
              >
                Close
              </button>

              <div className="grid grid-cols-2 gap-2 w-full h-full overflow-auto">
                {brandingMaterials.map((file, index) => (
                  <div
                    key={index}
                    className={`relative rounded-lg max-h-[100%] sm:h-full w-full flex items-center justify-center ${
                      hoveredIndex === index ? "bg-gray-200 bg-opacity-50" : ""
                    }`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    {hoveredIndex === index && (
                      <button
                        className="absolute top-2 right-2 bg-red-500 text-white rounded-lg px-2 py-1"
                        onClick={() =>
                          handleDeleteBM(
                            index,
                            combinedBrandMaterials,
                            brandingMaterials,
                            setMargin,
                            setBrandingMaterials,
                            updateFormData,
                            deleteImages,
                            formData,
                          )
                        }
                      >
                        Delete
                      </button>
                    )}
                    {file.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Uploaded"
                        className="max-w-full max-h-full"
                      />
                    ) : (
                      // Display a placeholder or icon for non-image files
                      <div className="p-2 max-w-full max-h-full">
                        <p className="break-words overflow-hidden">
                          {file.name}
                        </p>
                        {/* Show file name */}
                        {/* You can add icons or placeholders here */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
