import { GlobalProject } from "../Variables/Types";

const PopulateProjects = (project: GlobalProject) => {
  const generatedImages: string[] = [];

  // To generate a string[] of URL's to display
  project.campaign.generationImages.forEach((generationImage) => {
    generatedImages.push(generationImage.imageUrl);
  });

  return {
    id: String(project.id),
    realtyId: String(project.realtyId),
    name: String(project.name),
    campaign: {
      headline: String(project.campaign.headline),
      description: String(project.campaign.description),
      callToAction: String(project.campaign.callToAction),
      targetAudience: String(project.campaign.targetAudience),
      category: String(project.campaign.category),
      mediaSize: project.campaign.mediaSize,
      images: project.campaign.images,
      generationImages: generatedImages,
    },
  };
};

export default PopulateProjects;
