const putProfile = async (
  apiData: any,
  token: string,
): Promise<string | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Realty`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(apiData),
      },
    );
    /* if (response.status === 403) {
      window.location.href = `${process.env.REACT_APP_STRIPE_URL}`;
      return null;
    } */

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // use FE Logger when set up ('Profile updated Successfully')
    return data.id;
  } catch (error) {
    // use FE Logger when set up ('Error updating profile data')
    return null;
  }
};

export default putProfile;
