import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import Select, { StylesConfig } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import useProjectData from "../../Hooks/ProjectData";
import useProjectToAdd from "../../Hooks/ProjectToAdd";
import { useFormContext } from "../../Context/FormContext";
import { useProjectContext } from "../../Context/ProjectsContext";
// import EnhanceText from "../../Services/GPT/EnhanceText";
import PutProject from "../../Services/Project/PutProject";
import PostProject from "../../Services/Project/PostProject";
import enhanceTextWithAI from "../../Services/GPT/EnhanceText";
import "./ModalStyles.css";

import {
  PHONE_SIZE_CONSTANT,
  ENHANCE_TEXT_PROMPT,
  MAX_DESCRIPTION_CHAR_CNT_LG,
  MAX_DESCRIPTION_CHAR_CNT_MD,
  EnhanceAudiencePrompt,
} from "../../Utility/Variables/Constants";

// Define limits interface
interface Limits {
  newHeadlineCharLimit: number;
  newDescriptionCharLimit: number;
  newTargetAudienceCharLimit: number;
  newCallToActionCharLimit: number;
}

const Workflow2: React.FC = () => {
  const location = useLocation();
  const { newProject, enhance } = location.state || {};
  const navigate = useNavigate();
  const { formData, updateFormData } = useFormContext(); // Marketing Form
  const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently

  // Headline char limit
  const [headlineCharCount, setHeadlineCharCount] = useState(0);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const [descriptionOverflow, setDescriptionOverflow] = useState(false);
  const headlineRef = useRef<HTMLTextAreaElement | null>(null);
  const [headlineOverflow, setHeadlineOverflow] = useState(false);
  const targetAudienceRef = useRef<HTMLTextAreaElement | null>(null);
  const targetAudienceOverflow = useState(false);
  const [selectedAdType, setSelectedAdType] = useState<string | null>(
    "general",
  ); // Default to the first option
  const [prevAdType, setPrevAdType] = useState<string | null>("general");
  const [isAdTypeValid, setIsAdTypeValid] = useState(true);
  // Description cord limit
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  // Target Audience cord limit
  const [validationError, setValidationError] = useState("");
  // const [targetAudienceCharCount, setTargetAudienceCharCount] = useState(0);
  const { setCallToActionCharLimit } = useFormContext();

  const [headlineErrorMessage, setHeadlineErrorMessage] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [targetAudienceErrorMessage, setTargetAudienceErrorMessage] =
    useState("");

  const [headlineCharLimit, setHeadlineCharLimit] = useState(10); // default value
  const [descriptionCharLimit, setDescriptionCharLimit] = useState(100); // default value
  // const [targetAudienceCharLimit, setTargetAudienceCharLimit] = useState(30); // default value

  // const MAX_TARGET_AUDIENCE_CHAR_CNT = MAX_DESCRIPTION_CHAR_CNT_MD;

  const MAX_HEADLINE_CHAR_CNT = MAX_DESCRIPTION_CHAR_CNT_MD;

  const MAX_DESCRIPTION_CHAR_CNT = MAX_DESCRIPTION_CHAR_CNT_LG;

  const [isDescriptionEnhancing, setDescriptionEnhancing] = useState(false);
  const [isHeadlineEnhancing, setHeadlineEnhancing] = useState(false);
  // For mobile trigger
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < PHONE_SIZE_CONSTANT,
  );
  // to be used for custom confirmation message
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingLimits, setPendingLimits] = useState<Limits | null>(null);

  const headlineTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const charCount = newText.length;
    if (charCount <= headlineCharLimit) {
      updateFormData({ headlineText: newText });
      setHeadlineCharCount(charCount); // Update char count state
      if (newText.length > MAX_HEADLINE_CHAR_CNT) {
        setHeadlineOverflow(true);
      } else {
        if (headlineRef.current) {
          headlineRef.current.scrollTop = 0;
        }
        setHeadlineOverflow(false);
      }
    }
    setHeadlineErrorMessage("");
  };
  // useEffect to initialize formData with default ad type
  useEffect(() => {
    updateFormData({ category: "general" });
  }, []);
  useEffect(() => {
    // Recalculate headline char count when headlineText changes
    const newHeadlineCharCount = (formData.headlineText ?? "").length;
    setHeadlineCharCount(newHeadlineCharCount);
  }, [formData.headlineText]);

  useEffect(() => {
    // Recalculate description char count when descriptionText changes
    const newDescriptionCharCount = (formData.descriptionText ?? "").length;
    setDescriptionCharCount(newDescriptionCharCount);
  }, [formData.descriptionText]);

  const descriptionTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const charCount = newText.split(/\s+/).filter(Boolean).length;

    // Update char count and character overflow check
    if (charCount <= descriptionCharLimit) {
      updateFormData({ descriptionText: newText });
      setDescriptionCharCount(charCount); // Update char count state

      if (newText.length > MAX_DESCRIPTION_CHAR_CNT) {
        setDescriptionOverflow(true);
      } else {
        if (descriptionRef.current) {
          descriptionRef.current.scrollTop = 0;
        }
        setDescriptionOverflow(false);
      }
    }
    setDescriptionErrorMessage("");
  };

  const handleNextClick = () => {
    let hasError = false;

    if (!formData.headlineText || formData.headlineText.trim() === "") {
      setHeadlineErrorMessage("Headline cannot be empty");
      hasError = true;
    } else {
      setHeadlineErrorMessage(""); // Clear the headline error message if validation passes
    }

    if (!formData.descriptionText || formData.descriptionText.trim() === "") {
      setDescriptionErrorMessage("Description cannot be empty");
      hasError = true;
    } else {
      setDescriptionErrorMessage(""); // Clear the description error message if validation passes
    }

    if (
      !formData.targetAudienceText ||
      formData.targetAudienceText.trim() === ""
    ) {
      setTargetAudienceErrorMessage("Target audience cannot be empty");
      hasError = true;
    } else {
      setTargetAudienceErrorMessage(""); // Clear the target audience error message if validation passes
    }

    if (selectedAdType === null) {
      setIsAdTypeValid(false); // Indicate validation failure
      setValidationError("Please select a valid Type of Ad.");
      hasError = true;
    } else {
      setIsAdTypeValid(true); // Reset validation state on success
      setValidationError(""); // Clear validation error on success
    }

    if (hasError) {
      return; // If there are any errors, prevent navigation
    }

    navigate("/Workflow_3", {
      state: { newProject, enhance: true },
    });
  };

  // removed limits for target audience text
  const targetAudienceTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newText = e.target.value;
    // const charCount = newText.length;

    // Update the form data only if the char count is within the limit
    // if (charCount <= targetAudienceCharLimit) {
    updateFormData({ targetAudienceText: newText });
    // setTargetAudienceCharCount(charCount); // Update char count state
    // }

    // // Check if the character count exceeds the specified limit
    // if (newText.length > MAX_TARGET_AUDIENCE_CHAR_CNT) {
    //   setTargetAudienceOverflow(true);
    // } else {
    //   // Ensure the textarea scroll is reset to the top when there's no overflow
    //   if (targetAudienceRef.current) {
    //     targetAudienceRef.current.scrollTop = 0;
    //   }
    //   setTargetAudienceOverflow(false);
    // }
    setTargetAudienceErrorMessage("");
  };

  // For Handling Saved Projects
  const { addProject, doesProjectExist } = useProjectContext();
  // Must be called at top level - Custom React Hook updates when FormData Changes
  const apiData = useProjectData(formData);
  const projectToAdd = useProjectToAdd(formData);

  const onSave = () => {
    const key = String(formData.id);
    // First check for FE integrity
    if (!doesProjectExist(key)) {
      addProject(projectToAdd);
    }

    const handleSave = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
        },
      });

      if (newProject) {
        await PostProject(apiData, token);
      } else {
        await PutProject(apiData, token);
      }
    };

    handleSave().then(() => {
      /* Use FE logs when set up */
    });
    navigate("/Marketing");
  };
  // End-of-Saved Projects

  const handleHeadlineOverflow = (isOverflowing: boolean) => {
    // Handle text overflow logic here
    if (isOverflowing) {
      setHeadlineOverflow(true);
    } else {
      // Reset scroll and overflow state
      if (headlineRef.current) {
        headlineRef.current.scrollTop = 0;
      }
      setHeadlineOverflow(false);
    }
  };

  const enhanceHeadline = () => {
    setHeadlineEnhancing(true);
    enhanceTextWithAI(
      headlineCharLimit,
      ENHANCE_TEXT_PROMPT,
      formData.headlineText || "",
      "headlineText",
      MAX_DESCRIPTION_CHAR_CNT_MD,
      updateFormData,
      handleHeadlineOverflow,
      () => setHeadlineEnhancing(false),
    ).then(() => {
      /* Use FE logs when set up */
    });
  };
  // enhances text for audience
  const enhanceAudience = () => {
    enhanceTextWithAI(
      100,
      EnhanceAudiencePrompt,
      formData.targetAudienceText || "",
      "targetAudienceText",
      MAX_DESCRIPTION_CHAR_CNT_MD,
      updateFormData,
      handleHeadlineOverflow,
      () => setHeadlineEnhancing(false),
    ).then(() => {
      /* Use FE logs when set up */
    });
  };

  const handleDescriptionOverflow = (isOverflowing: boolean) => {
    // Handle text overflow logic here
    if (isOverflowing) {
      setDescriptionOverflow(true);
    } else {
      // Reset scroll and overflow state
      if (descriptionRef.current) {
        descriptionRef.current.scrollTop = 0;
      }
      setDescriptionOverflow(false);
    }
  };

  const enhanceDescription = () => {
    setDescriptionEnhancing(true);
    enhanceTextWithAI(
      descriptionCharLimit,
      ENHANCE_TEXT_PROMPT,
      formData.descriptionText || "",
      "descriptionText",
      MAX_DESCRIPTION_CHAR_CNT_LG,
      updateFormData,
      handleDescriptionOverflow,
      () => setDescriptionEnhancing(false),
    ).then(() => {
      /* Use FE logs when set up */
    });
  };

  useEffect(() => {
    if (enhance) {
      enhanceHeadline();
      enhanceDescription();
      enhanceAudience();
    }
  }, [enhance]);

  // const headlineTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //     const text = e.target.value;
  //     updateFormData({ headlineText: text });

  //     // Check if the number of characters exceeds the limit
  //     if (text.length > MAX_DESCRIPTION_CHAR_CNT_MD) {
  //         setHeadlineOverflow(true);
  //     } else {

  //         if (headlineRef.current) {
  //             headlineRef.current.scrollTop = 0;
  //         }

  //         setHeadlineOverflow(false);
  //     }
  // };
  // End-of-Headline handling

  // const descriptionTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //     const text = e.target.value;
  //     updateFormData({ descriptionText: text });

  //     // Check if the number of characters exceeds the limit
  //     if (text.length > MAX_DESCRIPTION_CHAR_CNT_LG) {
  //         setDescriptionOverflow(true);
  //     } else {

  //         if (descriptionRef.current) {
  //             descriptionRef.current.scrollTop = 0;
  //         }

  //         setDescriptionOverflow(false);
  //     }
  // };
  // End-of-Description handling

  // For Handling target Audience overflow: there is a better way to do this - will figure out later
  // const targetAudienceTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //     const text = e.target.value;
  //     updateFormData({ targetAudienceText: text });

  //     // Check if the number of characters exceeds the limit
  //     if (text.length > MAX_DESCRIPTION_CHAR_CNT_MD) {
  //         setTargetAudienceOverflow(true);
  //     } else {

  //         if (targetAudienceRef.current) {
  //             targetAudienceRef.current.scrollTop = 0;
  //         }

  //         setTargetAudienceOverflow(false);
  //     }
  // };
  // End-of-Target Audience handling

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < PHONE_SIZE_CONSTANT);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // End-of-Mobile trigger

  const openModal = (limits: Limits) => {
    setPendingLimits(limits);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdType(prevAdType);
  };

  const confirmChangeLimits = () => {
    if (pendingLimits) {
      const {
        newHeadlineCharLimit,
        newDescriptionCharLimit,
        // newTargetAudienceCharLimit,
        newCallToActionCharLimit,
      } = pendingLimits;

      updateFormData({ headlineText: "" });
      updateFormData({ descriptionText: "" });
      // updateFormData({ targetAudienceText: "" });

      setHeadlineCharLimit(newHeadlineCharLimit);
      setDescriptionCharLimit(newDescriptionCharLimit);
      // setTargetAudienceCharLimit(newTargetAudienceCharLimit);
      setCallToActionCharLimit(newCallToActionCharLimit);

      setPrevAdType(selectedAdType);
    }
    setIsModalOpen(false);
  };

  // functtion for changing limits
  function changeLimits(
    newHeadlineCharLimit: number,
    newDescriptionCharLimit: number,
    newTargetAudienceCharLimit: number,
    newCallToActionCharLimit: number,
  ) {
    // check whether limits are currently exceeded
    if (
      headlineCharCount > newHeadlineCharLimit ||
      descriptionCharCount > newDescriptionCharLimit
      // || targetAudienceCharCount > targetAudienceCharLimit
    ) {
      openModal({
        newHeadlineCharLimit,
        newDescriptionCharLimit,
        newTargetAudienceCharLimit,
        newCallToActionCharLimit,
      });
    } else {
      setPrevAdType(selectedAdType);
      setHeadlineCharLimit(newHeadlineCharLimit);
      setDescriptionCharLimit(newDescriptionCharLimit);
      // setTargetAudienceCharLimit(newTargetAudienceCharLimit);
      setCallToActionCharLimit(newCallToActionCharLimit);
    }
  }

  useEffect(() => {
    if (selectedAdType === "RealEstate") {
      changeLimits(350, 1050, 350, 350);
    } else if (selectedAdType === "tweet") {
      changeLimits(175, 560, 140, 140);
    } else if (selectedAdType === "general") {
      changeLimits(35, 70, 35, 35);
    } else {
      // default values if no specific category is selected
      setHeadlineCharLimit(210);
      setDescriptionCharLimit(700);
      // setTargetAudienceCharLimit(210);
      setCallToActionCharLimit(210);
    }
  }, [selectedAdType]);

  // For Ad option Dropdown
  type OptionType = {
    value: string;
    label: string;
  };

  const options = [
    { value: "general", label: "General" },
    { value: "RealEstate", label: "Real Estate" },
    { value: "tweet", label: "Tweet" },
  ];

  const customStyles: StylesConfig<OptionType> = {
    menu: (provided) => ({
      ...provided,
      top: isMobile ? "auto" : "unset",
      bottom: isMobile ? 0 : "unset",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      transform: isMobile ? "rotate(180deg)" : "rotate(0deg)", // Rotate the dropdown indicator
    }),

    control: (provided, state) => ({
      ...provided,
      borderWidth: "2px",
      borderRadius: "10px",
      borderColor: !isAdTypeValid
        ? "red"
        : state.isFocused
        ? "#A569FF"
        : "#64748b", // Conditionally apply red border
      boxShadow: state.isFocused ? "0 0 0 1px #A569FF" : "none",
      backgroundColor: "white",
      "&:hover": {
        color: "#8b5cf6",
      },
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#A569FF" : provided.backgroundColor,
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#A569FF" : provided.backgroundColor, // Change the background color of the option

      "&:hover": {
        backgroundColor: "#ede9fe",
        color: "black",
      },
    }),
  };
  // End-of-Ad Option Dropdown

  return (
    <div className="flex flex-col h-full w-full px-4 sm:px-5">
      <div className="grid grid-cols-2 gap-4 md:space-x-4">
        <div className="ml-2 w-full">
          <h1 className="sm:h-11 relative mt-4 text-black text-[24px] sm:text-[30px] font-medium font-['Montserrat']">
            Your Marketing Ads
          </h1>
        </div>

        <div className="sm:mt-2 md:mt-0 w-full py-4 pl-3 sm:px-3 flex justify-end items-start">
          <button
            onClick={onSave}
            className="px-2 py-2 bg-custom-select rounded-[10px] text-white text-[16px] sm:text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
          >
            <p> Save & Close </p>
          </button>
        </div>
      </div>

      <div className="flex flex-wrap w-full">
        <div className="flex-grow w-full md:w-[40vw] lg:h-fit p-2 pb-5 sm:pb-0 sm:p-4 text-right">
          <div className="h-fit md:h-[70%] md:w-[80%] flex flex-col justify-center text-right md:ml-auto sm:mt-2 md:mt-5">
            <div className="mt-4 sm:mt-0 md:mt-10 inline-flex text-left">
              <p className="w-[70%] text-left text-black text-[16px] sm:text-[19px] font-normal font-['Source Sans 3']">
                Create and generate text content for your campaign.
              </p>
            </div>

            <div className="relative mt-5 px-3 pt-3 pb-3 bg-white rounded-[15px] shadow justify-center items-center inline-flex">
              <div className="w-[100%] min-h-full relative">
                <div className="flex flex-row items-center md:items-start justify-center md:space-x-4">
                  <div className="w-full md:w-[65%] max-w-[100%]">
                    <h1 className="text-left text-[25px] text-black font-medium font-['Montserrat']">
                      Headline
                    </h1>
                    <p className="text-left pb-2 text-[16px] sm:text-[18px] text-black font-normal  step-6 font-['Montserrat']">
                      Give your campaign a creative title
                    </p>
                  </div>

                  <div className="w-full mt-3 md:w-[35%] max-w-[100%]">
                    {!isHeadlineEnhancing ? (
                      <button
                        onClick={enhanceHeadline}
                        // <button onClick={onHeadlineEnhance}
                        className="w-[80%] sm:w-full px-2 py-2 bg-violet-100 hover:drop-shadow-md rounded-[10px] text-purple-500 text-base font-medium font-['DM Sans']"
                      >
                        Enhance with AI
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="w-full flex justify-center py-2.5 px-5 me-2 text-sm font-medium text-purple-500 bg-violet-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-gray-200 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#9f7aea"
                          />
                        </svg>
                        Enhancing...
                      </button>
                    )}
                  </div>
                </div>

                <div className="relative w-[100%] h-[85px] left-0 md:mt-1 p-1 bg-white rounded-xl border-2 border-slate-500">
                  <textarea
                    ref={headlineRef}
                    placeholder="Write a headline..."
                    className={`w-full h-full pl-1 pb-18 text-[16px] text-black border-none outline-none align-text-top resize-none 
                                        ${
                                          headlineOverflow
                                            ? "overflow-y-auto"
                                            : "overflow-y-hidden"
                                        }`}
                    value={formData.headlineText || ""}
                    onChange={headlineTextChange}
                  />
                  <div className="absolute bottom-0 right-0 p-2 text-sm text-gray-600">
                    {headlineCharCount}/{headlineCharLimit}
                  </div>
                </div>
                {headlineErrorMessage && (
                  <div className="text-red-500 mt-2">
                    {headlineErrorMessage}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-5 px-3 pt-3 pb-3 bg-white rounded-[15px] shadow justify-center items-center inline-flex">
              <div className="w-[100%] min-h-full relative">
                <div className="flex flex-row items-center md:items-start justify-center md:space-x-4">
                  <div className="w-full md:w-[65%] max-w-[100%]">
                    <h1 className="text-left text-[25px] text-black font-medium font-['Montserrat'] step-7">
                      Description
                    </h1>
                    <p className="text-left pb-2 text-[16px] sm:text-[18px] text-black font-normal font-['Montserrat']">
                      Describe what your campaign is
                    </p>
                  </div>

                  <div className="w-full mt-3 md:w-[35%] max-w-[100%]">
                    {!isDescriptionEnhancing ? (
                      <button
                        onClick={enhanceDescription}
                        className="w-[80%] sm:w-full px-2 py-2 bg-violet-100 hover:drop-shadow-md rounded-[10px] text-purple-500 text-base font-medium font-['DM Sans']"
                      >
                        Enhance with AI
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="w-full flex justify-center py-2.5 px-5 me-2 text-sm font-medium text-purple-500 bg-violet-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-gray-200 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#9f7aea"
                          />
                        </svg>
                        Enhancing...
                      </button>
                    )}
                  </div>
                </div>

                <div className="w-[100%] left-0 md:mt-1 p-1 bg-white rounded-xl border-2 border-purple-500 relative">
                  <textarea
                    ref={descriptionRef}
                    placeholder="We are listing a property for sale in the GTA..."
                    className={`w-full h-full pl-1 pb-18 text-[16px] text-black border-none outline-none align-text-top resize-none 
                                        ${
                                          descriptionOverflow
                                            ? "overflow-y-auto"
                                            : "overflow-y-hidden"
                                        }`}
                    value={formData.descriptionText || ""}
                    onChange={descriptionTextChange}
                  />
                  <div className="absolute bottom-0 right-0 p-2 text-sm text-gray-600">
                    {descriptionCharCount}/{descriptionCharLimit}
                  </div>
                </div>
                {descriptionErrorMessage && (
                  <div className="text-red-500 mt-2">
                    {descriptionErrorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[20vw] lg:h-fit px-2 sm:px-4 md:pt-4 md:pb-4 md:pl-0 text-left overflow-hidden">
          <div className="sm:h-fit md:h-[70%] flex flex-col justify-center text-left mr-auto sm:mt-1 md:mt-10">
            <div className="w-[100%] md:mt-28 p-3 bg-white rounded-[15px] shadow justify-center items-center inline-flex">
              <div className="w-full min-h-full relative">
                <div className="w-full h-[25%] top-0">
                  <p className="left-0 top-0 text-black text-[25px] font-medium font-['Montserrat'] step-8">
                    Target Audience
                  </p>
                </div>

                <div className="w-full h-[75%] left-0 p-1 bg-white rounded-xl border-2 border-slate-500 mt-2 relative">
                  <textarea
                    ref={targetAudienceRef}
                    placeholder="Who is the target audiance..."
                    className={`w-full h-full pl-1 pb-18 text-[16px] text-black border-none outline-none align-text-top resize-none 
                                        ${
                                          targetAudienceOverflow
                                            ? "overflow-y-auto"
                                            : "overflow-y-hidden"
                                        }`}
                    value={formData.targetAudienceText || ""}
                    onChange={targetAudienceTextChange}
                  />

                  {/* <div className="absolute bottom-0 right-0 p-2 text-sm text-gray-600">
                    {targetAudienceCharCount}/{targetAudienceCharLimit}
                  </div> */}
                </div>
                {targetAudienceErrorMessage && (
                  <div className="text-red-500 mt-2">
                    {targetAudienceErrorMessage}
                  </div>
                )}
              </div>
            </div>

            <div className="w-[100%] mt-5 p-3 bg-white rounded-[15px] shadow justify-center items-center inline-flex">
              <div className="w-[100%] h-[100%] relative">
                <div className="w-[100%] h-[40%] top-0">
                  <p className="left-0 top-0 text-black text-[25px] font-medium font-['Montserrat']">
                    Type of Ad
                  </p>
                </div>

                <div className="mt-3 h-fit left-0 bg-white rounded-xl step-9">
                  <Select
                    options={options}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary25: "#ede9fe",
                        primary: "#8b5cf6",
                      },
                    })}
                    placeholder="Select Option"
                    defaultValue={options.find(
                      (option) => option.value === formData.category,
                    )}
                    value={options.find(
                      (option) => option.value === selectedAdType,
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        if (
                          !Array.isArray(selectedOption) &&
                          "value" in selectedOption
                        ) {
                          updateFormData({ category: selectedOption.value });
                          setSelectedAdType(selectedOption.value);
                          setValidationError(""); // Clear the validation error on selection
                        }
                      } else {
                        setValidationError("Please select a valid Type of Ad.");
                        updateFormData({ category: "" });
                        setSelectedAdType(null); // Use null instead of an empty string to indicate no selection
                      }
                    }}
                  />
                  {validationError && (
                    <div style={{ color: "red" }}>{validationError}</div>
                  )}
                </div>

                <div className="mt-2">
                  {" "}
                  {/* Dynamic description based on selection */}
                  {selectedAdType === "RealEstate" && (
                    <p className="text-sm text-gray-600">
                      This ad type is suitable for real estate listings, helping
                      you showcase properties in the best light.
                    </p>
                  )}
                  {selectedAdType === "general" && (
                    <p className="text-sm text-gray-600">
                      These are general basic ads with one image.
                    </p>
                  )}
                  {selectedAdType === "tweet" && (
                    <p className="text-sm text-gray-600">
                      Choose this ad type for engaging Twitter campaigns that
                      capture your audience's attention.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full relative">
              <div className="w-[40%] mt-5 md:mt-30 ml-auto">
                <button
                  onClick={handleNextClick}
                  className="w-full px-2 py-2 bg-purple-500 step-10 hover:bg-purple-600 rounded-[10px] text-white text-[20px] text-center font-medium font-['DM Sans']"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[20vw] p-4 pr-20 text-left overflow-hidden">
          <div className="w-full flex-none" />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Character Limit Exceeded"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            padding: "20px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <h2 className="modal-header">Character Limit Exceeded</h2>
        <p className="modal-body">
          Your content exceeds the character limit for this ad type. Are you
          sure you want to proceed? (All content will be erased)
        </p>
        <div className="modal-buttons">
          <button
            className="modal-button modal-button-yes"
            onClick={confirmChangeLimits}
          >
            Yes
          </button>
          <button className="modal-button modal-button-no" onClick={closeModal}>
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Workflow2;
