import React from "react";
import "./paymentfailure.css";
import logo_expanded from "../../Images/NavBar/SellStaticLogoExpanded.png";

const PaymentFailure: React.FC = () => {
  return (
    <div className="full-screen-container">
      <img src={logo_expanded} alt="SellStatic Logo" className="logo-image" />
      <div className="content-box">
        <h1>Subscription could not be verified</h1>
        <p>
          Please contact support at{" "}
          <a href="mailto:moizz@sellstatic.com">moizz@sellstatic.com</a>
        </p>
      </div>
    </div>
  );
};

export default PaymentFailure;
