const generateUID = (
  updateFormData: (updates: { [key: string]: any }) => void,
) => {
  const newId = `id-${Date.now()}`;

  updateFormData({
    id: newId,
  });
};

export default generateUID;
