export const PHONE_SIZE_CONSTANT = 640; // Distinguishes between a phone & tablet or small PC screen
// Used for defining when Large (LG) description containers overflow based on characters
// Temporary overflow solution, should look into a better way
export const MAX_DESCRIPTION_CHAR_CNT_LG = 312;
// Used for defining when Medium (MD) description containers overflow based on characters
export const MAX_DESCRIPTION_CHAR_CNT_MD = 154;
// Used as an Initial prompt for GPT enhance text calls
export const ENHANCE_TEXT_PROMPT =
  "Improve the following text to be more marketable. Please try to make the generated text ideal for a ";
export const MAX_WORDS_DESCRIPTION = 50; // Word limit for the description
export const EnhanceAudiencePrompt =
  "Based on the following text please provide a target audience, it should not be longer than 2 words ";
// Used in the profile page to define supported fonts
export const FONTS = [
  "Abril Fatface",
  "Alegreya",
  "Arvo",
  "BioRhyme",
  "Corben",
  "Cormorant",
  "Courier Prime",
  "Coustard",
  "DM Serif Display",
  "Eczar",
  "Frank Ruhl Libre",
  "Gravitas One",
  "IBM Plex Serif",
  "Jomolhari",
  "Libre Baskerville",
  "Lora",
  "Merriweather",
  "Neuton",
  "Noto Serif",
  "Old Standard TT",
  "PT Serif",
  "Playfair Display",
  "Prata",
  "Source Serif Pro",
  "Spectral",
  "Vollkorn",
  "Alegreya Sans",
  "Anton",
  "Archivo",
  "Archivo Narrow",
  "Cabin",
  "Catamaran",
  "Chivo",
  "Fira Sans",
  "IBM Plex Sans",
  "Karla",
  "Lato",
  "Libre Franklin",
  "Montserrat",
  "Muli",
  "Noto Sans",
  "Nunito",
  "Open Sans",
  "Oswald",
  "Oxygen",
  "PT Sans",
  "Pontano Sans",
  "Poppins",
  "Puritan",
  "Raleway",
  "Roboto",
  "Source Sans Pro",
  "Space Mono",
  "Titillium Web",
  "Ubuntu",
  "Varela",
  "Work Sans",
  "Caveat",
  "Courgette",
  "Delius",
  "Kalam",
  "Merienda",
  "Patrick Hand",
  "Permanent Marker",
  "Press Start 2P",
  "Satisfy",
  "VT323",
  "Noto Sans SC",
  "Noto Sans TC",
  "Noto Sans JP",
  "Noto Sans KR",
];
