import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import useProjectData from "../../Hooks/ProjectData";
import useProjectToAdd from "../../Hooks/ProjectToAdd";
import { useFormContext } from "../../Context/FormContext";
import { useProjectContext } from "../../Context/ProjectsContext";

import PostProject from "../../Services/Project/PostProject";
import PutProject from "../../Services/Project/PutProject";
// import scrapeWebsite from "../../Services/ScrapeWebsite/ScrapeWebsite";

import temp_sm from "../../Images/Marketing/TempSm.png";
import temp_md from "../../Images/Marketing/TempMd.png";
import temp_lg from "../../Images/Marketing/TempLg.png";
import temp_xl from "../../Images/Marketing/TempXl.png";

const Workflow1: React.FC = () => {
  const location = useLocation();
  const { newProject } = location.state || {};
  const navigate = useNavigate();
  const { formData, updateFormData } = useFormContext();
  const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently

  const [charCount, setCharCount] = useState(0);

  // const [scrapeUrl, setScrapeUrl] = useState("");
  // const [scrapedContent, setScrapedContent] = useState<string | null>(null);

  const campaignTitleCharLimit = 210;

  const [errorMessage, setErrorMessage] = useState("");
  const [mediaErrorMessage, setMediaErrorMessage] = useState("");

  // For Handling Saved Projects
  const { addProject, doesProjectExist } = useProjectContext();
  // Must be called at top level - Custom React Hook updates when FormData Changes
  const apiData = useProjectData(formData);
  const projectToAdd = useProjectToAdd(formData);

  const handleInputChange = (e: { target: { value: any } }) => {
    const input = e.target.value;
    const chars = input;
    if (chars.length <= campaignTitleCharLimit) {
      // Update formData only if within the char limit
      updateFormData({ campaignTitle: input });
      setCharCount(chars.length); // Update char count state
    }
    setErrorMessage("");
  };
  // function below autofills headline description and target audience textboxes using enhance with ai feature
  const handleCampaignDescChange = (e: { target: { value: any } }) => {
    const input = e.target.value;
    updateFormData({ campaignDesc: input });
    updateFormData({ headlineText: input });
    updateFormData({ calltoActionText: input });
    updateFormData({ descriptionText: input });
    updateFormData({ targetAudienceText: input });
    setErrorMessage("");
  };

  const onSave = () => {
    const key = String(formData.id);
    // First check for FE integrity
    if (!doesProjectExist(key)) {
      addProject(projectToAdd);
    }

    const handleSave = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
        },
      });

      if (newProject) {
        await PostProject(apiData, token);
      } else {
        await PutProject(apiData, token);
      }
    };

    handleSave().then(() => {
      /* Use FE logs when set up */
    });
    navigate("/Marketing");
  };
  // End-of-Saved Projects

  // const handleScrape = async () => {
  //   try {
  //     const content = await scrapeWebsite(scrapeUrl);
  //     // Store the scraped content in state
  //     // the scraped content in 'scrapedContent' variable
  //     setScrapedContent(content);
  //     //  Log the content for debugging purposes
  //     console.log("Scraped Content:", content);
  //     console.log(setScrapeUrl, scrapedContent);
  //   } catch (error) {
  //     console.error("Error scraping website:", error);
  //   }
  // };

  return (
    <div className="w-full h-full flex flex-col px-4 sm:px-5">
      <div className="grid grid-cols-2 gap-4 md:space-x-4">
        <div className="ml-2 sm:w-[120%] w-full">
          <h1 className="sm:h-11 relative mt-4 text-black text-[24px] sm:text-[30px] font-medium font-['Montserrat']">
            Your Marketing Ads
          </h1>
        </div>

        <div className="sm:mt-2 md:mt-0 w-full py-4 pl-3 sm:px-3 flex justify-end items-start">
          <button
            onClick={onSave}
            className="px-2 py-2 bg-custom-select rounded-[10px] text-white text-[16px] sm:text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
          >
            <p> Save & Close </p>
          </button>
        </div>
      </div>

      <div className="relative p-2 sm:p-4 mt-4 sm:mt-0 sm:ml-10 2xl:ml-50">
        <h2 className="text-[20px] font-medium tracking-tight text-black ">
          Title your Campaign
        </h2>

        <div className="relative mt-5 sm:w-3/4 w-full lg:max-w-2xl">
          <input
            type="text"
            value={formData.campaignTitle || ""}
            onChange={handleInputChange}
            placeholder="Campaign Title"
            className="w-full rounded-2xl border-2 border-slate-500 p-2 text-[16px] shadow-sm focus:outline-none step-3"
          />
          {/* Char count display */}
          <div className="absolute right-3 bottom-0 mb-2 mr-2 text-sm text-gray-600">
            {charCount}/{campaignTitleCharLimit}
          </div>
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
        </div>
      </div>

      <div className="relative p-2 sm:p-4 mt-4 sm:mt-0 sm:ml-10 2xl:ml-50">
        <h2 className="text-[20px] font-medium tracking-tight text-black ">
          Describe your Campaign
        </h2>

        <div className="relative mt-5 sm:w-3/4 w-full lg:max-w-2xl">
          <input
            type="text"
            value={formData.campaignDesc || ""}
            onChange={handleCampaignDescChange}
            placeholder="Campaign Description"
            className="w-full rounded-2xl border-2 border-slate-500 p-2 text-[16px] shadow-sm focus:outline-none"
          />
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
        </div>
      </div>

      {/* It represents a section that includes an input field
      for entering a URL to scrape and a button to trigger the web scraping process for testing purposes. */}

      <div className="relative p-2 sm:p-4 mt-4 sm:mt-0 sm:ml-10 2xl:ml-50">
        {/* <h2 className="text-[20px] font-medium tracking-tight text-black ">
          Web Scraping Tool
        </h2> */}

        {/* <div className="relative mt-5 sm:w-3/4 w-full lg:max-w-2xl">
          <input
            type="text"
            value={scrapeUrl}
            onChange={(e) => setScrapeUrl(e.target.value)}
            placeholder="Enter URL to scrape"
            className="w-full rounded-2xl border-2 border-slate-500 p-2 text-[16px] shadow-sm focus:outline-none"
          />
          <button
            onClick={handleScrape}
            className="mt-2 px-4 py-2 bg-purple-500 rounded-lg text-white text-[14px] sm:text-[16px] drop-shadow hover:drop-shadow-lg text-center hover:bg-purple-600 focus:outline-none font-medium font-['DM Sans']"
          >
            Scrape Website
          </button>
        </div> */}
      </div>

      <div className="mb-4 sm:mb-0 sm:ml-10 2xl:ml-50 pl-2 sm:pl-4">
        <h2 className="sm:mb-3 text-[20px] font-medium tracking-tight text-black step-4">
          Choose applicable Media Display Size
        </h2>
        {mediaErrorMessage && (
          <div className="text-red-500 mt-2">{mediaErrorMessage}</div>
        )}
      </div>

      <div className="h-full md:h-[60%] 2xl:h-[55%] md:p-4">
        <div className="p-2 sm:p-0 sm:flex sm:flex-wrap md:flex-nowrap grid h-full grid-cols-2 sm:grid-cols-5 sm:w-full 2xl:w-3/4 gap-4 sm:ml-10 xl:ml-20 2xl:ml-50">
          <div className="sm:w-[40%] order-3 sm:order-1 flex sm:justify-end sm:items-end md:justify-center md:items-center col-span-1">
            <div className="flex flex-col items-center rounded-2xl bg-white border-2 border-slate-500 p-3 shadow-md">
              <img
                className="object-contain w-full aspect-square"
                loading="lazy"
                src={temp_sm}
                alt="Square Media"
              />
              <label className="mt-5 flex w-full items-start gap-2">
                <input
                  type="checkbox"
                  checked={formData.square || false}
                  onChange={(e) => {
                    updateFormData({ square: e.target.checked });
                    setMediaErrorMessage(""); // Clear the media error message on selection
                  }}
                  className="mt-1.5"
                />

                <span className="text-base">
                  Facebook / Twitter / Instagram Post (Square)
                </span>
              </label>
            </div>
          </div>

          <div className="sm:w-[40%] order-2 flex justify-center items-center col-span-1">
            <div className="flex flex-col items-center rounded-2xl bg-white border-2 border-slate-500 p-3 shadow-md">
              <img
                loading="lazy"
                src={temp_md}
                className="aspect-[0.8] object-contain object-center w-full overflow-hidden"
                alt="Rectangle Media"
              />
              <label className="mt-5 flex w-full items-start gap-2">
                <input
                  type="checkbox"
                  name="vertical"
                  checked={formData.vertical || false}
                  onChange={(e) => {
                    updateFormData({ vertical: e.target.checked });
                    setMediaErrorMessage(""); // Clear the media error message on selection
                  }}
                  className="mt-1.5"
                />

                <span className="text-base">
                  LinkedIn / Facebook / Tiktok Post (Vertical)
                </span>
              </label>
            </div>
          </div>

          <div className="sm:w-[30%] order-4 sm:order-3 flex w-fit justify-center sm:items-start md:items-center col-span-1">
            <div className="flex flex-col col-span-1 p-3 items-center rounded-2xl bg-white border-2 border-slate-500 shadow-md">
              <img
                loading="lazy"
                src={temp_lg}
                className="object-contain object-center w-full overflow-hidden"
                alt=""
              />

              <label className="mt-5 flex w-full items-start gap-2">
                <input
                  type="checkbox"
                  checked={formData.story || false}
                  onChange={(e) => {
                    updateFormData({ story: e.target.checked });
                    setMediaErrorMessage(""); // Clear the media error message on selection
                  }}
                  className="mt-1.5"
                />

                <span className="text-base w-[90%] ">
                  Instagram Reel / Tiktok Story
                </span>
              </label>
            </div>
          </div>

          <div className="sm:w-[50%] order-1 sm:order-4 col-span-2 flex justify-start items-center md:justify-end md:items-end xl:pb-[4%] xl:pt-8 sm:mr-10 xl:mr-30 2xl:mr-50">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col w-full md:w-[80%] xl:w-[60%] space-y-4 rounded-2xl bg-white border-2 border-slate-500 p-3 shadow-md">
                <label className="flex items-start space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.custom || false}
                    onChange={(e) => {
                      updateFormData({ custom: e.target.checked });
                      setMediaErrorMessage(""); // Clear the media error message on selection
                    }}
                    className="mt-1.5"
                  />

                  <span>Custom Display Size:</span>
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    placeholder="Width"
                    className="text-center w-full"
                  />
                  <span>x</span>
                  <input
                    type="text"
                    placeholder="Height"
                    className="text-center w-full"
                  />
                </div>
              </div>

              <div className="flex items-center mt-4 rounded-2xl bg-white border-2 border-slate-500 p-3 shadow-md">
                <div className="flex flex-col">
                  <img
                    loading="lazy"
                    src={temp_xl}
                    className="object-contain object-center w-full overflow-hidden"
                    alt="Post"
                  />
                  <label className="mt-5 flex w-full items-start gap-2">
                    <input
                      type="checkbox"
                      name="square"
                      checked={formData.horizontal || false}
                      onChange={(e) => {
                        updateFormData({ horizontal: e.target.checked });
                        setMediaErrorMessage(""); // Clear the media error message on selection
                      }}
                      className="mt-1.5"
                    />

                    <span>
                      LinkedIn / Facebook / Instagram Post (Horizontal)
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 mt-40 sm:mt-0 sm:pr-5 xl:pr-20 2xl:pr-60">
        <div className="flex w-full justify-end ">
          <button
            onClick={() => {
              let hasError = false;

              if (
                !formData.campaignTitle ||
                formData.campaignTitle.trim() === ""
              ) {
                setErrorMessage("Title cannot be empty");
                hasError = true;
              } else {
                setErrorMessage(""); // Clear the title error message if validation passes
              }

              if (
                !formData.campaignDesc ||
                formData.campaignDesc.trim() === ""
              ) {
                setErrorMessage("Textbox cannot be empty");
                hasError = true;
              } else {
                setErrorMessage(""); // Clear the title error message if validation passes
              }

              if (
                !formData.square &&
                !formData.vertical &&
                !formData.story &&
                !formData.horizontal &&
                !formData.custom
              ) {
                setMediaErrorMessage("Please select a display size");
                hasError = true;
              } else {
                setMediaErrorMessage(""); // Clear the media error message if validation passes
              }

              if (hasError) {
                return; // If there are any errors, prevent navigation
              }

              navigate("/Workflow_2", { state: { newProject, enhance: true } });
              updateFormData({ campaignDesc: "" });
            }}
            className="rounded-lg bg-purple-500 px-10 py-3 text-white transition hover:bg-purple-600 step-5"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Workflow1;
