import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import UploadIcon from "../../Images/Common/DragAndDropIcon.png"; // Import your UploadIcon component

const ImageDropArea: React.FC<{
  onFilesUploaded: (files: File[]) => void;
  isMobile: boolean;
}> = ({ onFilesUploaded, isMobile }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // Reset error message
      setErrorMessage("");

      // Handle accepted files
      if (acceptedFiles.length > 0) {
        onFilesUploaded(acceptedFiles);
      }

      // Handle rejected files
      if (fileRejections.length > 0) {
        const errors = fileRejections.map(({ file }) => file.name).join(", ");
        setErrorMessage(
          `Rejected files: ${errors}. File type must be .jpg, .png, or .gif`,
        );
      }
    },
    [onFilesUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
    },
    maxSize: 10485760, // 10MB limit, adjust as needed
  });

  return (
    <div
      {...getRootProps()}
      className="flex-1 sm:flex-grow h-full flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4"
    >
      <input {...getInputProps()} />
      <div className="text-center">
        <div className="text-gray-500 mb-2">
          <div className="flex justify-center">
            <img src={UploadIcon} alt="Custom Logo" className="size-10" />
          </div>
          <p>{isMobile ? "Upload Files" : "Drag & Drop Logo"}</p>
          <p className="text-xs text-gray-400">
            {isMobile
              ? "click to upload files from mobile"
              : "or click to select files from device"}
          </p>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default ImageDropArea;
