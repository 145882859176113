const putProject = async (
  apiData: any,
  token: string,
): Promise<string | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Project`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(apiData),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // use FE Logger when set up
    // console.log('Project Updated Successfully:', data);
    return data.id;
  } catch (error) {
    // use FE Logger when set up
    // console.error('Error updating project data:', error);
    return null;
  }
};

export default putProject;
