import React, { useState, useEffect } from "react";

function DotLoader() {
  const [opacity, setOpacity] = useState([0, 0, 0]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(() => {
        switch (step) {
          case 0:
            return [1, 0, 0];
          case 1:
            return [1, 1, 0];
          case 2:
            return [1, 1, 1];
          case 3:
            return [0, 0, 0];
          default:
            return [0, 0, 0];
        }
      });

      setStep((prevStep) => (prevStep + 1) % 4);
    }, 350); // Transition time for dots

    return () => clearInterval(interval);
  }, [step]);

  return (
    <div>
      <p className="ml-5">
        {opacity.map((opac, index) => (
          <span
            key={index}
            className="text-[30px] transition-opacity duration-500 ease-in-out"
            style={{ opacity: opac }}
          >
            .
          </span>
        ))}
      </p>
    </div>
  );
}

export default DotLoader;
