/* eslint-disable */
/* prettier-ignore */

interface UserInfo {
  email: string;
  [key: string]: any;
}

const getUserInfo = async (token: string): Promise<string | null> => {
  try {
    const response = await fetch("https://sellstatic.us.auth0.com/userinfo", {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user info from Auth0.");
    }

    const userInfo: UserInfo = await response.json();
    return userInfo.email;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return null;
  }
};

export default getUserInfo;
