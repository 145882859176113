import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./paymentsuccess.css";
import { useAuth0 } from "@auth0/auth0-react"; // Import the useAuth0 hook
import logo_expanded from "../../Images/NavBar/SellStaticLogoExpanded.png";
import getSubscription from "../../Services/Subscription/GetSubscription";
import getUserInfo from "../../Services/Auth0/GetUserInfo";

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0(); // Initialize the Auth0 hook
  const [checkCount, setCheckCount] = useState(0);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        if (!isLoading && isAuthenticated) {
          // User is authenticated, proceed with subscription check
          const token = await getAccessTokenSilently(); // Get access token from Auth0

          if (!token) {
            throw new Error("Token not found.");
          }

          const response = await getSubscription(token);
          console.log("RESPONSE FROM SERVER:", response);

          if (response && response.subscriptionStatus === "Subscribed") {
            // User is subscribed, redirect to profile page
            console.log("User is subscribed");
            navigate("/profile");
          } else if (response === "not_found") {
            console.log("Redirecting to payment link");
            try {
              const email = await getUserInfo(token);
              if (email) {
                console.log("EMAIL", email);
                window.location.href = `${
                  process.env.REACT_APP_STRIPE_URL
                }?prefilled_email=${encodeURIComponent(email)}`;
              } else {
                navigate("/PaymentFailure");
              }
            } catch (error) {
              console.error("Error fetching user info:", error);
              navigate("/PaymentFailure");
            }
          } else {
            // User is not subscribed, continue checking up to 10 times
            console.log("User is not subscribed");
            if (checkCount < 1) {
              setTimeout(() => {
                setCheckCount((prevCount) => prevCount + 1);
              }, 2000); // Check again after 5 seconds
            } else {
              // If 10 checks passed and user is still not subscribed, redirect to payment link
              console.log("Redirecting to payment link");
              try {
                const email = await getUserInfo(token);
                if (email) {
                  console.log("EMAIL", email);
                  window.location.href = `${
                    process.env.REACT_APP_STRIPE_URL
                  }?prefilled_email=${encodeURIComponent(email)}`;
                } else {
                  navigate("/PaymentFailure");
                }
              } catch (error) {
                console.error("Error fetching user info:", error);
                navigate("/PaymentFailure");
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
        // Redirect to payment link on error or token not found
        console.log("User subscription error");
        setLoading(false); // Stop loading spinner
        navigate("/PaymentFailure");
      }
    };

    authenticateUser(); // Call authenticateUser immediately when the component mounts
  }, [
    navigate,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    checkCount,
  ]);

  return (
    <div className="payment-success">
      <div className="full-screen-container">
        <img src={logo_expanded} alt="SellStatic Logo" className="logo-image" />
        <div className="content-box">
          {loading && (
            <>
              <h1>Loading SellStatic...</h1>
              <div className="loading-spinner" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
