const ResetProjectData = (
  updateFormData: (updates: { [key: string]: any }) => void,
) => {
  updateFormData({
    realtyId: "",
    campaignTitle: "",
    headlineText: "",
    descriptionText: "",
    callToActionText: "",
    targetAudienceText: "",
    category: "",
    mediaSize: [],
    images: [],
    generationImages: [],
  });
};

export default ResetProjectData;
