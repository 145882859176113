const enhanceTextWithAI = async (
  charCount: number,
  prompt: string,
  text: string,
  field: string,
  maxCharCount: number,
  updateFormData: (updates: { [key: string]: any }) => void,
  handleTextOverflow: (isOverflowing: boolean) => void,
  onCompletion: () => void,
): Promise<void> => {
  const systemMessage = {
    role: "system",
    content: `${
      prompt + field
    }. If there is no prompt, come up with one. Make the your response less than ${charCount} characters (including special characters) no matter what.`,
  };
  const userMessage = {
    role: "user",
    content: text,
  };

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-4o",
        messages: [systemMessage, userMessage],
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const enhancedText = data.choices[0].message.content;
    updateFormData({ [field]: enhancedText });

    // Callback for handling text overflow
    handleTextOverflow(enhancedText.length > maxCharCount);
  } catch (error) {
    // use FE Logger when set up ('Error enhancing text')
  } finally {
    onCompletion();
  }
  // console.log(systemMessage, userMessage);
};

export default enhanceTextWithAI;
