const downloadImages = async (
  selectedPosts: string[],
  campaignName: String,
) => {
  // Map each imageUrl to a fetch operation and return an array of promises
  // So that if there are multiple images selected, they all download as a group
  const fetchPromises = selectedPosts.map((imageUrl, index) =>
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${campaignName}_${index + 1}`;
        document.body.appendChild(link);
        return link;
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      }),
  );

  // Wait for all fetch operations to complete
  const downloadLinks = await Promise.all(fetchPromises);

  // Trigger the download for each image and then remove the link
  downloadLinks.forEach((link) => {
    if (link) {
      link.click();
      document.body.removeChild(link);
    }
  });
};

export default downloadImages;
