import React from "react";
import mapboxgl from "mapbox-gl";
import { Suggestion } from "../Variables/Interfaces";

export const initializeMap = (
  mapContainerRef: React.RefObject<HTMLDivElement>,
  setMap: (value: React.SetStateAction<mapboxgl.Map | null>) => void,
) => {
  if (!mapContainerRef.current) return;
  const newMap = new mapboxgl.Map({
    container: mapContainerRef.current,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [-79.3832, 43.6532],
    zoom: 9,
  });

  newMap.on("load", () => {
    setMap(newMap);
  });

  return () => {
    if (newMap && newMap.remove) {
      newMap.remove();
    }
  };
};

export const handleAddressSearchOnPopulate = (
  address: string,
  map: mapboxgl.Map | null,
) => {
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      address,
    )}.json?access_token=${mapboxgl.accessToken}`,
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.features.length > 0) {
        const [lng, lat] = data.features[0].center;
        if (map && map.flyTo) {
          map.flyTo({
            center: [lng, lat],
            zoom: 15,
          });

          if (mapboxgl && mapboxgl.Marker) {
            new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
          }
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching geocoding results:", error);
    });
};

export const handleAddressSearch = (
  address: string,
  map: mapboxgl.Map | null,
  updateFormData: (updates: { [key: string]: any }) => void,
) => {
  // Use Mapbox Geocoding API to convert the address into coordinates
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${mapboxgl.accessToken}`,
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.features.length > 0) {
        const [lng, lat] = data.features[0].center;

        if (map) {
          map.flyTo({
            center: [lng, lat],
            zoom: 15,
          });

          new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
        }

        const selectedAddress = data.features[0].place_name;
        updateFormData({ address: selectedAddress });
      }
    });
};

export const handleSuggestionSelect = (
  place: Suggestion,
  map: mapboxgl.Map | null,
  setAddress: (value: React.SetStateAction<string>) => void,
  setSuggestions: (value: React.SetStateAction<Suggestion[]>) => void,
  setSuggestionSelected: (value: React.SetStateAction<boolean>) => void,
  updateFormData: (updates: { [key: string]: any }) => void,
) => {
  const selectedAddress = place.place_name;

  setAddress(selectedAddress);
  setSuggestions([]); // Clear suggestions
  setSuggestionSelected(true);

  if (map) {
    map.flyTo({
      center: place.center,
      zoom: 15,
    });

    new mapboxgl.Marker().setLngLat(place.center).addTo(map);
  }

  updateFormData({ address: selectedAddress });
};

const fetchSuggestions = async (
  searchText: string,
  setSuggestions: (value: React.SetStateAction<Suggestion[]>) => void,
) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${mapboxgl.accessToken}&autocomplete=true&limit=5`,
    );
    const data = await response.json();
    setSuggestions(data.features);
  } catch (error) {
    console.error("Error fetching suggestions:", error);
  }
};

export const handleAddressChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setAddress: (value: React.SetStateAction<string>) => void,
  setSuggestions: (value: React.SetStateAction<Suggestion[]>) => void,
  suggestionSelected: boolean,
  setSuggestionSelected: (value: React.SetStateAction<boolean>) => void,
) => {
  setAddress(e.target.value);

  if (!suggestionSelected) {
    fetchSuggestions(e.target.value, setSuggestions);
  } else {
    setSuggestionSelected(false); // Reset suggestionSelected when the user changes the address
  }
};
