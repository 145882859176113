import React, { createContext, useContext, useState } from "react";
import { DefaultProps, FormData } from "../Utility/Variables/Interfaces";

// Initialize with Partial to allow empty initial state
const defaultContext: {
  formData: Partial<FormData>;
  updateFormData: (updates: { [key: string]: any }) => void;
  callToActionCharCount: number; // add here to make gloablly accessible
  callToActionCharLimit: number;
  setCallToActionCharCount: (count: number) => void; // Add setter function with type
  setCallToActionCharLimit: (limit: number) => void;
} = {
  formData: {},
  updateFormData: () => {}, // dummy function for default context
  callToActionCharCount: 0, // add here to make gloablly accessible
  callToActionCharLimit: 30,
  setCallToActionCharCount: () => {}, // Dummy function for default context
  setCallToActionCharLimit: () => {},
};

const FormContext = createContext(defaultContext);
export const useFormContext = () => useContext(FormContext);

export const FormProvider: React.FC<DefaultProps> = ({ children }) => {
  // Initialize formData
  const [formData, setFormData] = useState<Partial<FormData>>({
    campaignTitle: "",
    campaignDesc: "",
    headlineText: "",
    descriptionText: "",
    targetAudienceText: "",
    callToActionText: "",
    square: false,
    vertical: false,
    story: false,
    horizontal: false,
    custom: false,
  });
  const [callToActionCharCount, updateCallToActionCharCount] = useState(0);
  const [callToActionCharLimit, updateCallToActionCharLimit] = useState(30);
  const updateFormData = (updates: { [key: string]: any }) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updates,
    }));
  };

  const setCallToActionCharCount = (count: number) => {
    // differentiate between set and update
    updateCallToActionCharCount(count);
  };

  const setCallToActionCharLimit = (limit: number) => {
    updateCallToActionCharLimit(limit);
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        callToActionCharCount,
        callToActionCharLimit,
        setCallToActionCharCount,
        setCallToActionCharLimit,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
