import React, { createContext, useState, useContext } from "react";
import { Profile } from "../Utility/Variables/Types";
import {
  DefaultProps,
  LoginContextType,
} from "../Utility/Variables/Interfaces";
import GetProfile from "../Services/Profile/GetProfile";

const LoginContext = createContext<LoginContextType>({
  isLoggedIn: false,
  profileData: null,
  isProfileEmpty: true,
  setProfileData: () => {},
  setIsLoggedIn: () => {},
  login: async (): Promise<void> => {},
});

export const useLogin = () => useContext(LoginContext);

export const LoginProvider: React.FC<DefaultProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // for user tracking later
  const [profileData, setProfileData] = useState<Profile | null>(null);
  const [isProfileEmpty, setIsEmpty] = useState(true);

  const login = async (token: string): Promise<void> => {
    const profile = await GetProfile(token);

    if (profile) {
      setIsEmpty(false);
      setProfileData(profile);
    } else {
      setIsEmpty(true);
    }
  };

  const value = {
    isLoggedIn,
    profileData,
    isProfileEmpty,
    setProfileData,
    setIsLoggedIn,
    login,
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};
