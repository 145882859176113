import { LocalProject } from "../Variables/Types";

const useLoadProject = (
  updateFormData: (updates: { [key: string]: any }) => void,
  project: LocalProject,
) => {
  updateFormData({
    id: String(project.id),
    realtyId: String(project.realtyId),
    campaignTitle: String(project.name),
    headlineText: String(project.campaign.headline),
    descriptionText: String(project.campaign.description),
    callToActionText: String(project.campaign.callToAction),
    targetAudienceText: String(project.campaign.targetAudience),
    category: String(project.campaign.category),
    mediaSize: project.campaign.mediaSize,
    images: project.campaign.images,
    generationImages: project.campaign.generationImages,
  });
};

export default useLoadProject;
