import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "../../Context/FormContext";

type CustomFontDropdownProps = {
  fonts: string[];
  selectedFont: string;
  onFontChange: (font: string) => void;
};

function FontDropdown({
  fonts,
  selectedFont,
  onFontChange,
}: CustomFontDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Type the ref with HTMLDivElement
  const { updateFormData } = useFormContext();

  useEffect(() => {
    // Type the event parameter with React.MouseEvent
    const handleClickOutside = (event: MouseEvent) => {
      // Ensure dropdownRef.current is a node to use .contains on it
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false); // Close the dropdown if click is outside
      }
    };

    // Add event listener when dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Effect dependencies

  const handleFontChange = (font: string) => {
    updateFormData({ font });
    onFontChange(font);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-custom-purpleBorder focus:border-custom-purpleBorder"
        onClick={() => setIsOpen(!isOpen)}
        style={{ fontFamily: selectedFont }}
      >
        {selectedFont}
      </button>
      {isOpen && (
        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 max-h-60 overflow-auto">
          {fonts.map((font: string) => (
            <button
              key={font}
              className="text-gray-800 block px-4 py-2 text-sm w-full text-left hover:bg-gray-100"
              onClick={() => handleFontChange(font)}
              style={{ fontFamily: font }}
            >
              {font}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default FontDropdown;
