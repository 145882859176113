import { Profile } from "../Variables/Types";

const PopulateProfile = async (
  profile: Profile,
  charCount: number,
  updateFormData: (updates: { [key: string]: any }) => void,
  handleTextOverflow: (isOverflowing: boolean) => void,
) => {
  // Check if profile data is available
  if (profile) {
    updateFormData({
      userId: profile.id,
      realtyName: profile.realtor.name,
      email: profile.realtor.email,
      phone: profile.realtor.phoneNumber,
      workPhone: profile.realtor.workNumber, // Need to change
      address: `${profile.realtor.address.unitNumber} ${profile.realtor.address.streetName} ${profile.realtor.address.city} ${profile.realtor.address.province} ${profile.realtor.address.zipCode} ${profile.realtor.address.country}`,
      instagram: profile.realtor.instagramLink,
      facebook: profile.realtor.facebookLink,
      twitter: profile.realtor.twitterLink,
      linkedIn: profile.realtor.linkedInLink, // Use the profile's LinkedIn if available, otherwise default
      brandFiles: profile.brandFiles, // need to include preliminary steps for logo & files to display them properly
      brandLogo: profile.brandLogo,
      primaryColor: profile.colors.primary,
      secondaryColor: profile.colors.secondary,
      tertiaryColor: profile.colors.tertiary,
      realtyDescription: profile.description,
      font: profile.font,
    });

    handleTextOverflow(profile.description.length > charCount);
  } else {
    // Profile is null
    console.log("Profile is Empty");
  }
};

export default PopulateProfile;
