import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPalette } from "@fortawesome/free-solid-svg-icons";
import downloadImages from "../../Utility/Functions/SelectedImageDownload";
import { useFormContext } from "../../Context/FormContext";
import { useProjectContext } from "../../Context/ProjectsContext";

import RenderPost from "../../Components/RenderPost/RenderPost";
import PutProject from "../../Services/Project/PutProject";
import useProjectData from "../../Hooks/ProjectData";
import useProjectToAdd from "../../Hooks/ProjectToAdd";

import { PHONE_SIZE_CONSTANT } from "../../Utility/Variables/Constants";
import UpdateProjectImages from "../../Utility/Functions/UpdateProjectImages";

const Workflow4: React.FC = () => {
  const location = useLocation();
  const { newProject } = location.state || {};
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently
  const { formData } = useFormContext(); // Marketing Form
  const { projectId } = location.state || {};
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < PHONE_SIZE_CONSTANT,
  );
  const [emailAddress] = useState<string>("");

  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [post, previewPost] = useState("");
  const [preview, setPreview] = useState(false);

  // For Handling Saved Projects
  const { projects, addProject, updateProject, doesProjectExist } =
    useProjectContext();
  // Must be called at top level - Custom React Hook updates when FormData Changes
  const apiData = useProjectData(formData);
  const projectToAdd = useProjectToAdd(formData);
  // Setting colors
  // const [primaryColor, setPrimaryColor] = useState<string>("#6b7280");
  // const [secondaryColor, setSecondaryColor] = useState<string>("#6b7280");
  // const [tertiaryColor, setTertiaryColor] = useState<string>("#6b7280");

  // // Change colors
  // const changePrimColor = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPrimaryColor(e.target.value);
  // };
  // const changeSecColor = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSecondaryColor(e.target.value);
  // };
  // const changeTerColor = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTertiaryColor(e.target.value);
  // };

  const onSave = () => {
    const key = String(formData.id);
    // First check for FE integrity
    if (!doesProjectExist(key)) {
      addProject(projectToAdd);
    }

    const handleSave = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
        },
      });

      await PutProject(apiData, token);
    };

    handleSave().then(() => {
      /* Use FE logs when set up */
    });
    navigate("/Marketing");
  };
  // End-of-Saved Projects

  // For Tracking Selected Images by URL
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const selectPost = (url: string, selectedPost: boolean) => {
    if (selectedPost) {
      // If selectedPost is true, add the URL to the array
      setSelectedPosts([...selectedPosts, url]);
    } else {
      // If selectedPost is false, remove the URL from the array
      setSelectedPosts(selectedPosts.filter((postUrl) => postUrl !== url));
    }
  };
  // End-of-Image URL Tracking

  // Effect Hooks
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (projectId && projects[projectId]) {
      const projectData = projects[projectId];
      if (projectData.campaign && projectData.campaign.generationImages) {
        const newImageUrls = projectData.campaign.generationImages;
        setImageUrls(newImageUrls);
      }
    } else {
      // Use FE logs when set up
      // console.log("Project ID or Data not found");
    }
  }, [projectId, projects]);
  // End-of-Effect Hooks

  // For handling Image Prompt Edits
  const [isEditing, setIsEditing] = useState(false);
  const [currentPreviewImage, setCurrentPreviewImage] = useState({
    imageUrl: "",
    imageId: "",
  });

  const extractImageId = (imageUrl: string) => {
    const parts = imageUrl.split("/");
    const idIndex = 10; // Index where the ID is located after splitting
    return parts[idIndex]; // Return the ID or null if it's not found
  };
  const handlePreviewPost = (imageUrl: string) => {
    setCurrentPreviewImage({ imageUrl, imageId: extractImageId(imageUrl) });
    setPreview(true);
  };

  const handleTextareaInput = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const textarea = event.target;

    if (!isMobile) {
      textarea.style.height = "auto";
      textarea.style.overflowY = "hidden";
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.overflowY = "scroll";
    }
  };

  const onEdit = async () => {
    const textareaElement = document.getElementById(
      "auto-resize-textarea",
    ) as HTMLTextAreaElement;
    setIsEditing(true);
    const prompt = textareaElement ? textareaElement.value : "";
    const { imageId } = currentPreviewImage;

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SELLSTATICBE_URL
        }/Project/${projectId}/refineImage?imageid=${imageId}&prompt=${encodeURIComponent(
          prompt,
        )}`,
        {
          method: "GET", // Assuming it's a GET request, change if it's POST
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        setIsEditing(false); // On POST request completion
        const errorBody = await response.json();
        const errorMessage = `HTTP error! Status: ${response.status}, Message: ${errorBody.message}`;
        throw new Error(errorMessage);
      }

      const updatedProject = await response.json();

      // Implement logging: console.log("project updated");
      // Find the latest image with the same ID
      // const latestImage = updatedProject.campaign.generationImages.find((img: { uid: string; }) => img.uid === imageId);
      // Sort the images by createdAt date in descending order and find the latest one

      const sortedImages =
        updatedProject.project.campaign.generationImages.sort(
          (a: { createdAt: string }, b: { createdAt: string }) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      const latestImage = sortedImages[0]; // The first item is the latest one

      if (latestImage) {
        // Update the currentPreviewImage state with the latest image URL
        // setCurrentPreviewImage({ imageUrl: latestImage.imageUrl, latestImage.uid });
        setCurrentPreviewImage((prevState) => ({
          ...prevState, // This keeps the existing state properties
          imageUrl: latestImage.imageUrl, // This updates the imageUrl
          imageId: latestImage.uid,
        }));

        previewPost(latestImage.imageUrl);
        setImageUrls((prevUrls) => [...prevUrls, latestImage.imageUrl]);
        UpdateProjectImages(updatedProject.project, updateProject); // .project because it returns .project .logs and .errorCount
        setIsEditing(false); // On POST request completion
      }
    } catch (error) {
      console.error("Error refining image:", error);
      setIsEditing(false); // On POST request completion
    }
  };
  // End-of-Image Prompt Edits

  const [confidenceValues, setConfidenceValues] = useState<
    Record<string, { confidence: string; recommended: boolean }>
  >({});
  let renderImages: string[] = [];

  if (newProject) {
    renderImages = imageUrls;
  } else if (formData.generationImages) {
    renderImages = formData.generationImages;
  }

  const generateRandomConfidence = () => {
    const min = 76;
    const max = 98;
    const confidenceValue = Math.floor(Math.random() * (max - min + 1)) + min;
    return `Marketability score: ${confidenceValue}%`; // includes the confidence score with the text
  };

  useEffect(() => {
    setConfidenceValues((currentConfidences) => {
      const updatedConfidences = { ...currentConfidences };
      let maxConfidence = 0;
      let recommendedUrl = "";

      // Identify the highest confidence among existing URLs
      Object.entries(updatedConfidences).forEach(([url, { confidence }]) => {
        const confidenceValue = parseInt(
          confidence.replace("Marketability score:", "").replace("%", ""),
          10,
        );
        if (confidenceValue > maxConfidence) {
          maxConfidence = confidenceValue;
          recommendedUrl = url;
        }
      });

      // Add or update confidence for new URLs and determine if any have higher confidence
      renderImages.forEach((url) => {
        if (!updatedConfidences[url]) {
          const confidence = generateRandomConfidence();
          const confidenceValue = parseInt(
            confidence.replace("Marketability score:", "").replace("%", ""),
            10,
          );
          updatedConfidences[url] = {
            confidence,
            recommended: false,
          };
          if (confidenceValue > maxConfidence) {
            maxConfidence = confidenceValue;
            recommendedUrl = url;
          }
        }
      });

      // Update recommended flag based on the highest confidence
      Object.keys(updatedConfidences).forEach((url) => {
        updatedConfidences[url].recommended = url === recommendedUrl; // Evaluates to true if matching url
      });

      return updatedConfidences;
    });
  }, [renderImages]);

  // Map over imageUrls to render the Post components
  const renderPosts = renderImages.map((imageUrl, index) => {
    // Check if imageUrl is not null or undefined
    if (imageUrl) {
      const { confidence, recommended } = confidenceValues[imageUrl] || {};

      return (
        <RenderPost
          key={index}
          imageUrl={imageUrl}
          confidence={confidence} // Use the generated confidence
          previewPost={() => {
            previewPost(imageUrl);
            handlePreviewPost(imageUrl); // Updated function name
          }}
          selectPost={(selected: boolean) => {
            selectPost(imageUrl, selected);
          }}
          recommended={recommended} // Use the isHighest flag to determine if recommended
        />
      );
    }
    return null; // Return null if imageUrl is falsy
  });

  const composeMailToEmail = () => {
    if (selectedPosts.length === 0) {
      alert("Please select at least one image before sending an email.");
      return;
    }

    downloadImages(selectedPosts, String(formData.campaignTitle)).then(() => {
      /* Handle FE Logging */
    });

    // Email subject and body
    const emailSubject = "Exciting New Property Listing!";
    const emailBodyContent = `        
            Dear [Client's Name],
    
            I'm thrilled to present a fantastic opportunity: a stunning property at [Property Address]. Its perfect for your needs, this home could be your dream come true!
    
            Let's schedule a viewing at your convenience. Looking forward to helping you find your perfect home!
    
            Best regards,
    
            [Your Name]
        `;

    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject,
    )}&body=${encodeURIComponent(emailBodyContent)}`;

    // Open the default mail client
    window.location.href = mailtoLink;
  };

  //  removed functions for sharing on twitter

  // t

  return (
    <div className="relative flex flex-col w-full h-[130%] sm:h-full">
      <div className="flex flex-col px-4 sm:px-5 pb-4">
        <div className="grid grid-cols-2 gap-4 md:space-x-4">
          <div className="sm:ml-2 w-full">
            <h1 className="sm:h-11 relative mt-4 text-black text-[24px] sm:text-[30px] font-medium font-['Montserrat'] workflow4-intro">
              Your Marketing Ads
            </h1>
          </div>

          <div className="w-full py-4 pl-3 sm:px-3 flex justify-end items-start">
            <button
              onClick={onSave} // will need to change to onSave but getting error for now due to images
              className="px-2 py-2 bg-custom-select rounded-[10px] text-white text-[16px] sm:text-[20px] drop-shadow hover:bg-custom-navBar focus:outline-none text-center font-medium font-['DM Sans']"
            >
              <p> Save & Close </p>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="w-full mt-auto sm:ml-2 md:w-[90%] max-w-[100%]">
            <p className="w-[70%] text-left text-black text-[19px] font-normal font-['Source Sans 3']">
              Share your marketing campaign:
            </p>

            <div className="flex flex-row w-[65%] md:w-[50%] sm:w-[70%] h-[40px] mt-4 space-x-4 workflow4-download-email">
              <button
                className="flex-1 flex justify-center items-center rounded-[10px] bg-white drop-shadow hover:drop-shadow-lg"
                onClick={() =>
                  downloadImages(selectedPosts, String(formData.campaignTitle))
                }
                disabled={selectedPosts.length === 0}
              >
                Download
              </button>
              <button
                onClick={composeMailToEmail}
                className="flex-1 flex justify-center items-center rounded-[10px] bg-white drop-shadow hover:drop-shadow-lg"
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="h-full px-4 pb-4 grid grid-cols-1 sm:grid-cols-2 sm:auto-rows-[50%] md:auto-rows-[60%] md:grid-cols-3 lg:grid-cols-4 overflow-auto gap-4 workflow4-choose-templates">
        {renderPosts}
      </div>

      {preview && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative bg-white p-4 flex flex-col rounded-lg max-h-full max-w-[96%] sm:max-w-[80%]">
              <button
                className="ml-auto top-2 right-2 bg-custom-select text-white px-2 py-1 mb-4 rounded"
                onClick={() => {
                  setPreview(false);
                }}
              >
                Close
              </button>

              <div className="flex flex-col sm:flex-row w-full h-full">
                <div className="w-full sm:w-4/6 max-h-[700px]">
                  <img
                    src={post}
                    alt="post_1"
                    className="object-contain rounded-[15px] w-full h-full"
                  />
                </div>

                <div className="sm:w-2/6 flex justify-center">
                  <div className="p-4">
                    <h1> Would you like to change anything about the ad? </h1>
                    <textarea
                      id="auto-resize-textarea"
                      className="mt-2 border border-gray-300 resize-none overflow-hidden rounded px-2 py-1 w-full"
                      placeholder="Enter modifications here"
                      onInput={handleTextareaInput}
                    />

                    {!isEditing ? (
                      <div>
                        {/* <div className="flex justify-center items-center">
                          <div className="flex justify-center items-center w-[85%] text-white my-1.5 py-1.5 px-2 rounded-[20px] bg-custom-purple">
                            <FontAwesomeIcon
                              icon={faPalette}
                              style={{ fontSize: "2.5vmin" }}
                              className="pr-1.5"
                            />
                            <h1
                              className="py-2 text-center font-bold"
                              style={{ fontSize: "2vmin" }}
                            >
                              Adjust the color palette below:
                            </h1>
                          </div>
                        </div> */}
                        {/* <div className="flex flex-row justify-between w-full pt-3">
                          <div className="flex flex-col items-center">
                            <div
                              className="w-20 h-20 rounded-lg shadow-lg cursor-pointer"
                              style={{ backgroundColor: primaryColor }}
                              onClick={() =>
                                document.getElementById("editPrimary")?.click()
                              }
                            />
                            <p>Primary</p>
                            <input
                              type="color"
                              id="editPrimary"
                              className="w-6 h-6 opacity-0"
                              onChange={changePrimColor}
                            />
                          </div>
                          <div className="flex flex-col items-center">
                            <div
                              className="w-20 h-20 rounded-lg shadow-lg cursor-pointer"
                              style={{ backgroundColor: secondaryColor }}
                              onClick={() =>
                                document
                                  .getElementById("editSecondary")
                                  ?.click()
                              }
                            />
                            <p>Secondary</p>
                            <input
                              type="color"
                              id="editSecondary"
                              className="w-6 h-6 opacity-0"
                              onChange={changeSecColor}
                            />
                          </div>
                          <div className="flex flex-col items-center">
                            <div
                              className="w-20 h-20 rounded-lg shadow-lg cursor-pointer"
                              style={{ backgroundColor: tertiaryColor }}
                              onClick={() =>
                                document.getElementById("editTertiary")?.click()
                              }
                            />
                            <p>Tertiary</p>
                            <input
                              type="color"
                              id="editTertiary"
                              className="w-6 h-6 opacity-0"
                              onChange={changeTerColor}
                            />
                          </div>
                        </div> */}
                        <div className="flex justify-center items-center">
                          <button
                            className="bg-custom-select text-white py-2.5 px-5 text-sm font-medium mb-4 rounded-lg"
                            onClick={onEdit}
                          >
                            Edit Post
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="py-2.5 px-5 me-2 text-sm font-medium text-white bg-custom-select rounded-lg border border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-gray-200 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#A569FF"
                          />
                        </svg>
                        Loading...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workflow4;
