const getSubscription = async (token: string): Promise<any | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Subscription/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      },
    );

    console.log("Subscription Status", response);

    /* if (response.status === 404) {
      console.log("Subscription not found will redirect");
      return "not_found";
    } */

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.log("Profile does not exist");
    return null;
  }
};

export default getSubscription;
