import React, { useState, useEffect, useRef } from "react";
import DotLoader from "../../Components/DotLoader/DotLoader";
import SendMessage from "../../Services/GPT/SellStaticAI";

const SellStaticAI: React.FC = () => {
  const [messages, setMessages] = useState([
    { user: "bot", text: "Hello, welcome to SellStatic AI" },
  ]);
  const [input, setInput] = useState("");
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() !== "") {
      SendMessage(input, setMessages, setIsLoading).then(() => {
        /* Use FE logs when set up */
      });
      setInput("");
    }
  };

  const processText = (text: string) => {
    return text
      .split("\n")
      .map((line, index, array) => {
        const isListItem = line.match(/^\d+\./);
        const prevIsListItem = index > 0 && array[index - 1].match(/^\d+\./);
        if (isListItem) {
          return line;
        }
        if (prevIsListItem && !isListItem) {
          return `\u00A0\u00A0${line}`;
        }
        return line;
      })
      .join("\n");
  };

  const Message = ({ text, user }: { text: string; user: string }) => {
    const processedText = user === "bot" ? processText(text) : text;
    const paragraphs = processedText.split("\n").filter((p) => p.trim() !== "");
    return (
      <div
        className={`my-4 p-3 rounded-lg ${
          user === "user"
            ? "bg-custom-purple text-white ml-auto max-w-[90%]"
            : "bg-gray-300 text-gray-800 mr-auto"
        }`}
      >
        {paragraphs.map((paragraph, index) => (
          <div key={index} className={index > 0 ? "mt-2" : ""}>
            {paragraph}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    setInput(textarea.value);

    textarea.style.height = "auto";
    textarea.style.height = `${Math.max(
      40,
      Math.min(textarea.scrollHeight, 150),
    )}px`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default action
      handleSend(e as unknown as React.FormEvent<HTMLFormElement>); // Send message
    }
  };

  return (
    <div className="w-full h-full flex flex-col px-5 overflow-auto">
      <div className="flex">
        <div className="ml-2 w-full">
          <h1 className="h-11 relative mt-4 text-black text-[30px] font-medium font-['Montserrat'] message-sellstatic-ai">
            SellStatic AI
          </h1>
          <p className="text-black text-[18px] tracking-tight w-full mt-3.5">
            Ask our custom tailored AI any real estate questions you may have
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center h-[84%] max-h-[88%] bg-gray-200 p-4">
        <div className="flex flex-col justify-start h-full w-full sm:h-[90%] sm:w-[60%] rounded-lg">
          <div className="flex flex-col mb-4 h-[90%] overflow-y-auto">
            {messages.map((message, index) => (
              <Message key={index} text={message.text} user={message.user} />
            ))}
            {isLoading && <DotLoader />}
            <div ref={endOfMessagesRef} />
          </div>
          <form
            onSubmit={handleSend}
            className={`flex p-0.5 bg-white items-center border border-custom-purple rounded-lg ${
              isInputFocused ? "outline-none ring-2 ring-custom-purple" : ""
            }`}
          >
            <textarea
              value={input}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              rows={1}
              className="flex-1 rounded-l-lg px-3 py-2 outline-none resize-none search-sellstatic-ai"
              placeholder="Message SellStatic AI..."
            />
            <button
              type="submit"
              className="h-10 px-2 py-1 rounded-lg text-white bg-custom-purple hover:bg-violet-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 10.5l7.5-7.5m0 0l7.5 7.5M12 3v18"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SellStaticAI;
