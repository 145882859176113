import { FormData } from "../Variables/Interfaces";

const PrepareProject = (formData: Partial<FormData>) => {
  return {
    id: formData.id,
    realtyId: String(formData.realtyId || "unnamedRealty"),
    status: 0,
    name: String(formData.campaignTitle || "unnamedProject"),
    campaign: {
      address: {
        country: String(formData.country || "Canada"),
        province: String(formData.province || "ON"),
        city: String(formData.city || "Brampton"),
        streetName: String(formData.streetName || "12 Gatlin St"),
        unitNumber: String(formData.unitNumber || "NA"),
        zipCode: String(formData.zipCode || "L6Y 0R6"),
      },
      headline: String(
        formData.headlineText || "Beautiful Modern Townhouse for Sale",
      ),
      description: String(
        formData.descriptionText ||
          "This is a beautiful townhouse for sale in the heart of Brampton. It has 4 bedrooms and 4 bathrooms. It has a total of 2900 sqft. Some unique features include hardwood floors, a built-in deck, and 9 ft ceilings.",
      ),
      callToAction: String(formData.callToActionText || "Book Viewing"),
      targetAudience: String(
        formData.targetAudienceText || "Home buyers in GTA",
      ),
      category: String(formData.category),
      mediaSize: formData.mediaSize || [{ width: 1024, height: 1024 }],
      templates: [],
      images: [
        "https://www.insauga.com/wp-content/uploads/2022/03/Henna-St-2.jpg",
        "https://www.insauga.com/wp-content/uploads/2022/03/Henna-St-3.jpg",
        "https://www.insauga.com/wp-content/uploads/2022/03/Henna-St-4.jpg",
        "https://www.insauga.com/wp-content/uploads/2022/03/Henna-St-5.jpg",
      ],
      generationImages: [],
    },
  };
};

export default PrepareProject;
