import React from "react";
import { Profile } from "../Variables/Types";
import { FormData } from "../Variables/Interfaces";
import {
  ENHANCE_TEXT_PROMPT,
  MAX_DESCRIPTION_CHAR_CNT_MD,
  MAX_WORDS_DESCRIPTION,
} from "../Variables/Constants";

import PostImages from "../../Services/S3/PostImages";
import GetImages from "../../Services/S3/GetImages";
import PostProfile from "../../Services/Profile/PostProfile";
import PutImages from "../../Services/S3/PutImages";
import PutProfile from "../../Services/Profile/PutProfile";
import GetProfile from "../../Services/Profile/GetProfile";
import EnhanceText from "../../Services/GPT/EnhanceText";

// Handler functions for color change
export const handlePrimaryColorChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  updateFormData: (updates: { [key: string]: any }) => void,
  setPrimaryColor: (value: React.SetStateAction<string>) => void,
) => {
  updateFormData({ primaryColor: event.target.value });
  setPrimaryColor(event.target.value);
};

export const handleSecondaryColorChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  updateFormData: (updates: { [key: string]: any }) => void,
  setSecondaryColor: (value: React.SetStateAction<string>) => void,
) => {
  updateFormData({ secondaryColor: event.target.value });
  setSecondaryColor(event.target.value);
};

export const handleTertiaryColorChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  updateFormData: (updates: { [key: string]: any }) => void,
  setTertiaryColor: (value: React.SetStateAction<string>) => void,
) => {
  updateFormData({ tertiaryColor: event.target.value });
  setTertiaryColor(event.target.value);
};

export const handleBrandingMaterials = (
  uploadedFiles: File[],
  brandingMaterials: File[],
  setFileOverflow: React.Dispatch<React.SetStateAction<boolean>>,
  isMobile: boolean,
  setBrandingMaterials: React.Dispatch<React.SetStateAction<File[]>>,
  setMargin: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const totalFiles = brandingMaterials.length + uploadedFiles.length;

  if (totalFiles < 1) {
    setFileOverflow(false);
  } else if (totalFiles > 1 && isMobile) {
    setFileOverflow(true);
  } else {
    setFileOverflow(true);
  }

  setBrandingMaterials((prevFiles) => [...prevFiles, ...uploadedFiles]); // Accumulate new files along with the existing ones

  if (totalFiles > 2) {
    setMargin(true); // On Add
  }
};

export const handleDeleteBM = (
  index: number,
  combinedBrandMaterials: {
    url: string;
    type: string;
    source: string;
    originalIndex: number;
  }[],
  brandingMaterials: File[],
  setMargin: React.Dispatch<React.SetStateAction<boolean>>,
  setBrandingMaterials: React.Dispatch<React.SetStateAction<File[]>>,
  updateFormData: (updates: { [key: string]: any }) => void,
  deleteImages: (index: number) => Promise<void>,
  formData: Partial<FormData>,
) => {
  const itemToDelete = combinedBrandMaterials[index];

  if (itemToDelete.source === "localFiles") {
    const updatedFiles = [...brandingMaterials];
    updatedFiles.splice(itemToDelete.originalIndex, 1); // Remove using the original index

    if (updatedFiles.length <= 2) {
      setMargin(false);
    }

    setBrandingMaterials(updatedFiles); // Update the state
  } else if (itemToDelete.source === "s3Urls" && formData.brandFiles) {
    const updatedBrandFiles = formData.brandFiles.filter(
      (_, idx) => idx !== itemToDelete.originalIndex,
    );
    updateFormData({ brandFiles: updatedBrandFiles });
    deleteImages(itemToDelete.originalIndex).then(() => {
      /* Use FE logs ('Image deleted') when set up */
    });
  }
};

export const descriptionTextChange = (
  e: React.ChangeEvent<HTMLTextAreaElement>,
  updateFormData: (updates: { [key: string]: any }) => void,
  setDescriptionWordCount: React.Dispatch<React.SetStateAction<number>>,
  descriptionRef: React.MutableRefObject<HTMLTextAreaElement | null>,
  setDescriptionOverflow: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const newText = e.target.value;
  const words = newText.match(/\b[-?(\w+)?]+\b/gi); // Match words using regex
  const wordCount = words ? words.length : 0;

  if (wordCount <= MAX_WORDS_DESCRIPTION) {
    updateFormData({ realtyDescription: newText });
    setDescriptionWordCount(wordCount); // Update word count state
  }

  // Check if the number of characters exceeds the limit
  if (newText.length > MAX_DESCRIPTION_CHAR_CNT_MD) {
    setDescriptionOverflow(true);
  } else {
    if (descriptionRef.current) {
      // eslint-disable-next-line no-param-reassign
      descriptionRef.current.scrollTop = 0;
    }
    setDescriptionOverflow(false);
  }
};

export const saveProfile = async (
  key: string,
  token: string,
  logo: File | undefined,
  brandingMaterials: File[],
  type: string,
  apiData: Profile,
) => {
  const logoTransfer = new DataTransfer();
  const brandingTransfer = new DataTransfer();
  // Check if logo exists
  if (logo instanceof File) {
    logoTransfer.items.add(logo);
  }

  for (const file of brandingMaterials) {
    brandingTransfer.items.add(file);
  }

  const userLogo = logoTransfer.files;
  const userBrandMaterials = brandingTransfer.files;

  if (type === "POST") {
    await PostImages(key, userLogo, token, "Logo");
    // eslint-disable-next-line no-param-reassign
    apiData.brandLogo = String(await GetImages("Logo", token));

    await PostImages(key, userBrandMaterials, token, "Branding");
    // eslint-disable-next-line no-param-reassign
    apiData.brandFiles = await GetImages("Branding", token);

    await PostProfile(apiData, token)
      .then(() => {
        /* Use FE logs when set up */
      })
      .catch(() => {
        /* Use FE logs when set up */
      });
  } else {
    await PutImages(key, userLogo, token, "Logo");
    // eslint-disable-next-line no-param-reassign
    apiData.brandLogo = String(await GetImages("Logo", token));

    await PutImages(key, userBrandMaterials, token, "Branding");
    // eslint-disable-next-line no-param-reassign
    apiData.brandFiles = await GetImages("Branding", token);

    await PutProfile(apiData, token)
      .then(() => {
        /* Use FE logs when set up */
      })
      .catch(() => {
        /* Use FE logs when set up */
      });
  }
};

// Function for updating profile data
export const updateProfile = async (
  token: string,
  setBrandingMaterials: React.Dispatch<React.SetStateAction<File[]>>,
  setProfileData: (profile: Profile) => void,
) => {
  const updatedProfile = await GetProfile(token);
  if (updatedProfile) {
    setProfileData(updatedProfile);
    setBrandingMaterials([]); // clear local branding materials after save
  }
};

export const enhanceText = (
  formData: Partial<FormData>,
  updateFormData: (updates: { [key: string]: any }) => void,
  setIsEnhancing: React.Dispatch<React.SetStateAction<boolean>>,
  handleTextOverflow: (isOverflowing: boolean) => void,
) => {
  setIsEnhancing(true);

  EnhanceText(
    MAX_WORDS_DESCRIPTION,
    ENHANCE_TEXT_PROMPT,
    formData.realtyDescription || "",
    "realtyDescription",
    MAX_DESCRIPTION_CHAR_CNT_MD,
    updateFormData,
    handleTextOverflow,
    () => setIsEnhancing(false),
  )
    .then(() => {
      // Handle the successful resolution of the promise here
      // Implement FE logging: some-logger("Enhancement result:", r);
      // Perform any other actions you need after the text has been enhanced
    })
    .catch((error) => {
      // Handle any errors that occurred during 'enhanceTextWithAI'
      console.error("Error during text enhancement:", error);
    });
};
