const GetImages = async (id: string, token: string): Promise<Array<string>> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/s3/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      },
    );

    if (!response.ok) {
      const errorBody = await response.json();
      const errorMessage = `HTTP error! Status: ${response.status}, Message: ${errorBody.message}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data.preSignedUrls;
  } catch (error) {
    // use FE Logger when set up
    // console.error('Error posting project data:', error);
    return [];
  }
};

export default GetImages;
