import { useMemo } from "react";
import { FormData } from "../Utility/Variables/Interfaces";

const useProfileData = (formData: Partial<FormData>) => {
  const address = formData.address || "";
  const words = address.split(/\s|,/).filter((word) => word.trim() !== "");
  // Dependency on formData
  return useMemo(
    () => ({
      id: String(formData.userId) || "",
      realtor: {
        name: String(formData.realtyName || ""),
        email: String(formData.email || ""),
        phoneNumber: String(formData.phone || ""),
        workNumber: String(formData.workPhone || ""),
        address: {
          country: String(words[7] || ""),
          province: String(words[4] || ""),
          city: String(words[3] || ""),
          streetName: String(`${words[1]} ${words[2]}` || ""),
          unitNumber: String(words[0] || ""),
          zipCode: String(`${words[5]} ${words[6]}` || ""),
        },
        facebookLink: String(formData.facebook || ""),
        instagramLink: String(formData.instagram || ""),
        linkedInLink: String(formData.linkedIn || ""),
        twitterLink: String(formData.twitter || ""),
      },
      colors: {
        primary: String(formData.primaryColor || "#3C4065"),
        secondary: String(formData.secondaryColor || "#A569FF"),
        tertiary: String(formData.tertiaryColor || "#686DA7"),
      },
      font: String(formData.font || "Source Sans Pro"),
      description: String(formData.realtyDescription || ""),
      brandLogo: String(formData.brandLogo) || "",
      brandFiles: formData.brandFiles || [],
      projectIds: [],
    }),
    [formData],
  );
};

export default useProfileData;
