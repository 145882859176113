import React, { createContext, useState, useContext } from "react";
import { LocalProject } from "../Utility/Variables/Types";
import {
  DefaultProps,
  ProjectContextType,
} from "../Utility/Variables/Interfaces";

const defaultValue: ProjectContextType = {
  projects: {},
  addProject: () => {},
  updateProject: () => {},
  doesProjectExist: () => false,
};

const ProjectContext = createContext<ProjectContextType>(defaultValue);

export const ProjectProvider: React.FC<DefaultProps> = ({ children }) => {
  const [projects, setProjects] = useState<Record<string, LocalProject>>({});

  const addProject = (project: LocalProject) => {
    const key = project.id;

    // Functional Update to ensure the states is the most up-to-date state before addition
    // Implemented for PopulateProjects projects addition of returned array
    setProjects((prevProjects) => {
      if (!prevProjects[key]) {
        return { ...prevProjects, [key]: project };
      }
      return prevProjects;
    });
  };

  // Only updates project images for now
  const updateProject = (key: string, updatedProjectImages: string[]) => {
    setProjects((prevProjects) => {
      // Check if the project exists in the current state
      if (prevProjects[key]) {
        return {
          ...prevProjects,
          [key]: {
            ...prevProjects[key],
            campaign: {
              ...prevProjects[key].campaign,
              generationImages: updatedProjectImages,
            },
          },
        };
      }
      // Return the previous state if the project does not exist
      return prevProjects;
    });
  };

  const doesProjectExist = (key: string) => {
    return !!projects[key];
  };

  const value = {
    projects,
    addProject,
    updateProject,
    doesProjectExist,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);
