import { useMemo } from "react";
import { FormData } from "../Utility/Variables/Interfaces";

const useProjectData = (formData: Partial<FormData>) => {
  // Dependency on formData
  return useMemo(
    () => ({
      id: formData.id,
      realtyId: String(formData.realtyId || ""),
      status: 0,
      name: String(formData.campaignTitle || ""),
      campaign: {
        address: {
          country: String(formData.country || ""),
          province: String(formData.province || ""),
          city: String(formData.city || ""),
          streetName: String(formData.streetName || ""),
          unitNumber: String(formData.unitNumber || ""),
          zipCode: String(formData.zipCode || ""),
        },
        headline: String(formData.headlineText || ""),
        description: String(formData.descriptionText || ""),
        callToAction: String(formData.callToActionText || ""),
        targetAudience: String(formData.targetAudienceText || ""),
        category: String(formData.category) || "",
        mediaSize: formData.mediaSize || [],
        templates: [],
        images: [],
        generationImages: [],
      },
    }),
    [formData],
  );
};

export default useProjectData;
