import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { PreviewImage } from "../../Utility/Variables/Interfaces";

const RenderPost: React.FC<PreviewImage> = ({
  imageUrl,
  confidence,
  previewPost,
  selectPost,
  recommended,
}) => {
  const [togglePreview, setToggle] = useState(false);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxSelected(event.target.checked);
  };

  const handleCheckBox = (e: React.MouseEvent) => {
    // Check if the click event target is the child div by its id or other attribute
    const target = e.target as HTMLElement;
    const update = !isCheckboxSelected;

    if (
      target &&
      (target.id === "toggle-preview" || target.id === "show-preview")
    ) {
      return;
    }
    setIsCheckboxSelected(update);
    selectPost(update);
  };

  return (
    <div
      className={`relative h-full bg-white rounded-[15px] drop-shadow hover:drop-shadow-lg flex flex-col justify-end p-4
             ${recommended ? "border-[4px] border-custom-purple" : ""}`}
      onClick={handleCheckBox}
    >
      <input
        type="checkbox"
        className="absolute w-4 h-4 top-4 right-4"
        checked={isCheckboxSelected}
        onChange={handleOnChange}
      />

      <div
        className={`relative flex-grow w-full flex pb-1 ${
          recommended
            ? "justify-start items-center"
            : "justify-center items-center"
        }`}
      >
        {recommended && (
          <div className="flex justify-center items-center w-[37%] h-[70px] text-white mr-4 py-1 rounded-[20px] bg-custom-purple">
            <p
              className="px-2 text-center w-full flex-shrink"
              style={{ fontSize: "2vmin" }}
            >
              We Recommend
            </p>
          </div>
        )}
        <div
          className={`flex justify-center items-center text-white text-center px-4 py-2 rounded-[8px] bg-custom-select ${
            recommended ? "w-[50%]" : "w-[60%]"
          }`}
        >
          <FontAwesomeIcon
            icon={faUserCheck}
            className="text-white flex-shrink-0"
            style={{ fontSize: "4vmin", paddingRight: "2vmin" }}
          />
          <p className="flex-grow" style={{ fontSize: "2vmin" }}>
            {confidence}
          </p>
        </div>
      </div>

      <div
        id="toggle-preview"
        className={`relative h-3/4 w-full rounded-[15px] flex-grow justify-center items-center inline-flex 
                            ${togglePreview ? "bg-opacity-50" : ""}`}
        onMouseEnter={() => {
          setToggle(true);
        }}
        onMouseLeave={() => {
          setToggle(false);
        }}
        onClick={() => {
          previewPost();
        }}
      >
        <img
          src={imageUrl}
          alt={imageUrl}
          className="object-cover rounded-[15px] h-full w-full"
        />

        {togglePreview && (
          <button
            id="show-preview"
            className="absolute inset-0 flex justify-center items-center text-white text-[25px]
                                                                     bg-gray-400 opacity-80 rounded-[15px]"
          >
            Preview
          </button>
        )}
      </div>
    </div>
  );
};

export default RenderPost;
