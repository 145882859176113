import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormContext } from "../../Context/FormContext";
import { useProjectContext } from "../../Context/ProjectsContext";
import { Image, Photo } from "../../Utility/Variables/Interfaces";

import useProjectToAdd from "../../Hooks/ProjectToAdd";
import EnhanceText from "../../Services/GPT/EnhanceText";
import GenerateImages from "../../Services/Project/GET/GenerateImages";
import generateAIImages from "../../Services/GPT/GenerateImage";

import {
  ENHANCE_TEXT_PROMPT,
  MAX_DESCRIPTION_CHAR_CNT_MD,
  PHONE_SIZE_CONSTANT,
} from "../../Utility/Variables/Constants";

import PutProject from "../../Services/Project/PutProject";
import PostProject from "../../Services/Project/PostProject";
import PostImages from "../../Services/S3/PostImages";
import UpdateProjectImages from "../../Utility/Functions/UpdateProjectImages";

import GetImages from "../../Services/S3/GetImages";
import PutImages from "../../Services/S3/PutImages";
import PrepareProject from "../../Utility/Functions/PrepareProject";
import logo from "../../Images/Marketing/logo.png";
import victorianhouse from "../../Images/Marketing/victorian_house.png";
import { useLogin } from "../../Context/LoginContext";

import putProfile from "../../Services/Profile/PutProfile";

import {
  handlePrimaryColorChange,
  handleSecondaryColorChange,
  handleTertiaryColorChange,
} from "../../Utility/Modules/ProfileModule";

const Workflow3: React.FC = () => {
  const [isMobile] = useState(window.innerWidth < PHONE_SIZE_CONSTANT);
  const [isSmallMobile] = useState(window.innerWidth < 400);
  const location = useLocation();
  const { newProject, enhance } = location.state || {};
  const navigate = useNavigate();
  const { formData, updateFormData } = useFormContext(); // Marketing Form
  const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently

  // For Handling Saved Projects
  const { addProject, updateProject, doesProjectExist } = useProjectContext();

  // For handling Image Uploads
  const [images, setImages] = useState<Image[]>([]);
  const [imageFiles, setImageFiles] = useState<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [callToActionOverflow, setCTAOverflow] = useState(false);
  const {
    callToActionCharCount,
    callToActionCharLimit,
    setCallToActionCharCount,
  } = useFormContext();
  const MAX_CTA_CHAR_CNT = MAX_DESCRIPTION_CHAR_CNT_MD; // Assuming this constant is defined appropriately
  const callToActionRef = useRef<HTMLTextAreaElement | null>(null);

  const [preview, setPreview] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const [aiPrompt, setAiPrompt] = useState("");
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  const [isPromptDisabled, setIsPromptDisabled] = useState(false);

  const { profileData, setProfileData } = useLogin();

  // Stock Image searcher const
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [comingSoonMessage, setComingSoonMessage] = useState("");

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  // for modal window
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleColourModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalWidth = 1000;
  const modalHeight = modalWidth / 1.6;

  const [query, setQuery] = useState("");
  const [photos, setPhotos] = useState<Photo[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showButton, setShowButton] = useState(false);
  // end

  const handleSelectImage = (index: number, selected: boolean) => {
    if (selected) {
      setSelectedImages([...selectedImages, `image_${index}`]);
    } else {
      setSelectedImages(
        selectedImages.filter((img) => img !== `image_${index}`),
      );
    }
  };

  const handleToggleModal = () => {
    setPreview((prev) => !prev);
  };

  const [ctaErrorMessage, setCtaErrorMessage] = useState("");

  // For Saved Projects
  // Must be called at top level - Custom React Hook updates when FormData Changes
  // const apiData = useProjectData(formData); // Must be different to handle images
  const projectToAdd = useProjectToAdd(formData);

  const onSave = () => {
    const key = String(formData.id);
    // First check for FE integrity
    if (!doesProjectExist(key)) {
      addProject(projectToAdd);
    }

    const handleSave = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_API_AUDIENCE,
        },
      });

      const apiData = PrepareProject(formData);

      if (newProject) {
        // Deal with customer images first so that I can store the in the db as pre-signed urls
        // from S3 (stored as strings in the db
        await PostImages(key, imageFiles, token, "Project");
        apiData.campaign.images = await GetImages(key, token);
        await PostProject(apiData, token);
      } else {
        await PutImages(key, imageFiles, token, "Project");
        apiData.campaign.images = await GetImages(key, token);
        await PutProject(apiData, token);
      }
    };

    handleSave().then(() => {
      /* Use FE logs when set up */
    });
    navigate("/Marketing");
  };
  // End-of-Saved Projects

  // For Generating Ads
  const [isLoading, setIsLoading] = useState(false);
  const handleGenerate = () => {
    setIsLoading(true); // Start loading
    const key = String(formData.id);
    let projectKey; // define project on post/put

    let hasError = false;

    if (!formData.callToActionText || formData.callToActionText.trim() === "") {
      setCtaErrorMessage("Call to action cannot be empty");
      hasError = true;
    } else {
      setCtaErrorMessage(""); // Clear the CTA error message if validation passes
    }

    if (hasError) {
      setIsLoading(false); // Stop loading if there's an error
      return; // If there are any errors, prevent further execution
    }

    const handleGen = async () => {
      setIsLoading(true);

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
        },
      });

      const apiData = PrepareProject(formData);

      // Handle POST with FE check
      // Not best practice but what I am able to do for now
      // Integrity is based on Project name
      if (newProject && !doesProjectExist(key)) {
        // Deal with customer images first so that I can store them in the db as pre-signed urls
        // from S3 (stored as strings in the db)
        await PostImages(key, imageFiles, token, "Project");
        apiData.campaign.images = await GetImages(key, token);
        // need code here to POST the project with the pre-signed URLS (e.g., update formData.images)
        projectKey = await PostProject(apiData, token);
        addProject(projectToAdd);
      } else {
        // Deal with customer images first so that I can store them in the db as pre-signed urls
        // from S3 (stored as strings in the db)
        await PutImages(key, imageFiles, token, "Project");
        apiData.campaign.images = await GetImages(key, token);
        // need code here to PUT the project with the pre-signed URLS (e.g., update formData.images)
        projectKey = await PutProject(apiData, token);
      }

      if (projectKey) {
        const project = await GenerateImages(projectKey, token);

        if (project) {
          UpdateProjectImages(project, updateProject);
          navigate("/Workflow_4", {
            state: { projectId: projectKey, newProject },
          });
        } else {
          // Use FE logs when set up
          // console.log('Error in image generation. Navigating back to Workflow 3');
          navigate("/Workflow_3", { state: { newProject } });
        }
      } else {
        // Use FE logs when set up
        // console.log("Error in project posting. Navigating back to Workflow 3");
        navigate("/Workflow_3", { state: { newProject } });
      }

      setIsLoading(false);
    };

    handleGen().then(() => {
      /* Use FE logs when set up */
    });
  };

  // End-of-Generating Ads

  const handleGenerateImages = async () => {
    if (aiPrompt.trim()) {
      setIsLoading(true);
      setIsGenerateDisabled(true); // Disable the button
      setIsPromptDisabled(true);
      await generateAIImages(aiPrompt, setGeneratedImages, setIsLoading, () => {
        // Optionally handle completion logic here
      });
    }
  };

  // For Handling Call to Action Text
  const [isEnhancing, setIsEnhancing] = useState(false);
  const handleTextOverflow = (isOverflowing: boolean) => {
    // Handle text overflow logic here
    if (isOverflowing) {
      setCTAOverflow(true);
    } else {
      // Reset scroll and overflow state
      if (callToActionRef.current) {
        callToActionRef.current.scrollTop = 0;
      }
      setCTAOverflow(false);
    }
  };

  useEffect(() => {
    const newCharCount = (formData.callToActionText ?? "").length;
    setCallToActionCharCount(newCharCount);
  }, [formData.callToActionText]);

  const enhanceCallToAction = () => {
    setIsEnhancing(true);
    EnhanceText(
      callToActionCharLimit,
      ENHANCE_TEXT_PROMPT,
      formData.callToActionText || "",
      "callToActionText",
      MAX_DESCRIPTION_CHAR_CNT_MD,
      updateFormData,
      handleTextOverflow,
      () => setIsEnhancing(false),
    ).then(() => {
      /* Use FE logs when set up */
    });
  };

  useEffect(() => {
    if (enhance) {
      enhanceCallToAction();
    }
  }, [enhance]);

  const callToActionTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newText = e.target.value;
    const charCount = newText.split(/\s+/).filter(Boolean).length;

    // Update the form data only if the char count is within the limit
    if (charCount <= callToActionCharLimit) {
      updateFormData({ callToActionText: newText });
      setCallToActionCharCount(charCount); // Update char count state
    }

    // Check if the character count exceeds the specified limit
    if (newText.length > MAX_CTA_CHAR_CNT) {
      setCTAOverflow(true); // Indicate overflow state for characters
    } else {
      // Ensure the textarea does not unnecessarily show a scroll bar
      if (callToActionRef.current) {
        callToActionRef.current.scrollTop = 0; // Reset scroll to top if within character limit
      }
      setCTAOverflow(false); // Reset overflow state for characters
    }
    setCtaErrorMessage("");
  };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const createFileList = (files: File[]) => {
    const fileList = new DataTransfer();
    for (const file of files) {
      fileList.items.add(file);
    }
    return fileList.files as FileList;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const newImages: Image[] = [];
      const newImageFiles: File[] = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const imageUrl = URL.createObjectURL(selectedFile);
        newImages.push({ name: selectedFile.name, url: imageUrl });
        newImageFiles.push(selectedFile);
      }
      setImages((prevImages) => [...prevImages, ...newImages]);
      setImageFiles((prevImageFiles) =>
        prevImageFiles
          ? createFileList([...prevImageFiles, ...newImageFiles])
          : createFileList(newImageFiles),
      );
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    if (imageFiles) {
      const updatedImageFiles = [...imageFiles];
      updatedImageFiles.splice(index, 1);
      setImageFiles(createFileList(updatedImageFiles));
    }
  };
  // End-of-Image Uploads

  const handleUseSelectedImages = async () => {
    const selectedImagesToStore = generatedImages.filter((_, index) =>
      selectedImages.includes(`image_${index}`),
    );

    const proxyUrl = "https://corsproxy.io/?";

    const newFilesPromises = selectedImagesToStore.map(async (url, index) => {
      const proxiedUrl = proxyUrl + encodeURIComponent(url);
      const response = await fetch(proxiedUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      return new File([blob], `generated_image_${index + 1}.png`, {
        type: "image/png",
      });
    });

    const newFiles = await Promise.all(newFilesPromises);

    if (newFiles.length > 0) {
      const fileList = new DataTransfer();
      newFiles.forEach((file) => fileList.items.add(file));

      // Call handleFileChange with the new files
      const event = {
        target: {
          files: fileList.files,
        },
      };
      handleFileChange(event as React.ChangeEvent<HTMLInputElement>);
    } else {
      console.error("No new files created from selected images");
    }

    setPreview(false); // Close the modal after using the images
  };

  // Handle Stock Images
  const handleUseSelectedImagesforStock = async () => {
    const selectedImagesToStore = photos
      .filter((photo) => selectedImages.includes(`image_${photo.id}`))
      .map((photo) => photo.src.original);

    const proxyUrl = "https://corsproxy.io/?"; // Example of another CORS proxy

    const newFilesPromises = selectedImagesToStore.map(async (url, index) => {
      const proxiedUrl = proxyUrl + encodeURIComponent(url);
      const response = await fetch(proxiedUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      return new File([blob], `generated_image_${index + 1}.png`, {
        type: "image/png",
      });
    });
    const newFiles = await Promise.all(newFilesPromises);
    if (newFiles.length > 0) {
      const fileList = new DataTransfer();
      newFiles.forEach((file) => fileList.items.add(file));
      // Call handleFileChange with the new files
      const event = {
        target: {
          files: fileList.files,
        },
      };
      handleFileChange(event as React.ChangeEvent<HTMLInputElement>);
    } else {
      console.error("No new files created from selected images");
    }

    closeModal(); // Close stock image searcher
  };

  // For Handling Loading Messages
  const messages = [
    "We are creating your images...",
    "This may take a minute...",
    "Almost there...",
    "Your images are looking great...",
    "Hang tight...",
    "Thanks for choosing SellStatic...",
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentMessage(
          (prev) => messages[(messages.indexOf(prev) + 1) % messages.length],
        );
        setFade(true);
      }, 500); // Transition time
    }, 8000); // Time per message

    return () => clearInterval(interval);
  }, []);
  // End-of-Handling Loading Messages
  // Stock Image searcher
  const handleSearch = async (page: number = 1) => {
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${query}&per_page=8&page=${page}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PEXELS_API_KEY || "",
          },
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setPhotos(data.photos);
      setTotalPages(Math.ceil(data.total_results / 8));
      setCurrentPage(page);
      setShowButton(true);
    } catch (error) {
      console.error("Error fetching images from Pexels API:", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handleSearch(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handleSearch(currentPage - 1);
    }
  };
  // End of Stock Image Searcher
  // Branding colour states
  const [primaryColor, setPrimaryColor] = useState("#6b7280"); // default gray
  const [secondaryColor, setSecondaryColor] = useState("#8b5cf6"); // default purple
  const [tertiaryColor, setTertiaryColor] = useState("#475569");

  useEffect(() => {
    if (profileData?.colors) {
      setPrimaryColor(profileData.colors.primary);
      setSecondaryColor(profileData.colors.secondary);
      setTertiaryColor(profileData.colors.tertiary);
    }
  }, [profileData]);

  const Canvas = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      if (!context) return;

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw white background
      context.fillStyle = primaryColor;
      context.fillRect(0, 0, 400, 400);

      context.fillStyle = tertiaryColor;
      context.fillRect(30, 220, 340, 180);

      context.fillStyle = secondaryColor;
      context.fillRect(45, 80, 310, 290);
      context.fillRect(75, 0, 250, 20);

      context.fillStyle = "black";
      context.fillRect(20, 125, 360, 50);

      context.fillStyle = "white";
      context.font = "25px Abel";
      context.fillText("Selling properties made easy?", 53, 115);

      // Draw the "Maximize your sales" text
      context.font = "40px Actor";
      context.fillText("Maximize your sales", 30, 160);

      // Draw the "call to action" text
      context.font = "20px Abel";
      context.fillText("call us now", 150, 193);

      // Draw the face inside the bottom rectangle
      const Image = new window.Image();
      Image.src = logo;
      Image.onload = () => {
        context.drawImage(Image, 160, 0, 80, 80);
      };
      const image = new window.Image();
      image.src = victorianhouse;
      image.onload = () => {
        context.drawImage(image, 70, 200, 260, 200);
      };
    }, [primaryColor, secondaryColor, tertiaryColor]);

    return <canvas ref={canvasRef} width={400} height={400} />;
  };

  const [selectedButton, setSelectedButton] = useState<string>("DALL_E");

  const descriptions: { [key: string]: string } = {
    DALL_E:
      "DALL-E 3 generates diverse and detailed images from textual descriptions, including realistic photos, fantasy art, concept designs, architectural renderings, and abstract pieces.",
    Gemini:
      "Google Gemini generates a wide range of images from text prompts, including realistic photos, digital art, illustrations, and abstract designs.",
    Midjourney:
      "Midjourney generates a variety of images from text prompts, including concept art, character designs, landscapes, and abstract art.",
  };

  const handleButtonClick = (button: string) => {
    if (button === "Gemini" || button === "Midjourney") {
      setComingSoonMessage("Coming Soon!");
      setSelectedButton(button);
      return;
    }
    setComingSoonMessage("");
    setSelectedButton(button);
  };

  const handleBackButtonClick = () => {
    setGeneratedImages([]);
    setIsPromptDisabled(false); // Re-enable the prompt input
    setIsGenerateDisabled(false); // // Re-enable the button
  };

  const onColourSave = async () => {
    const updatedProfileData = {
      ...profileData,
      colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        tertiary: tertiaryColor,
      },
    };

    // Update the state with the new color values
    setProfileData(updatedProfileData);
    updateFormData({
      ...formData,
      colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        tertiary: tertiaryColor,
      },
    });

    // Fetch the token
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    // Send the updated profile data to the server
    await putProfile(updatedProfileData, token)
      .then(() => {
        /* Use FE logs when set up */
      })
      .catch(() => {
        /* Use FE logs when set up */
      });

    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="relative w-full h-full flex flex-col px-4 sm:px-5">
      <div className="grid grid-cols-2 gap-4 md:space-x-4">
        <div className="ml-2 w-full">
          <h1 className="sm:h-11 relative mt-4 text-black text-[24px] sm:text-[30px] font-medium font-['Montserrat']">
            Your Marketing Ads
          </h1>
        </div>

        <div className="w-full py-4 pl-3 sm:px-3 flex justify-end items-start">
          <button
            onClick={onSave}
            className="px-2 py-2 bg-custom-select rounded-[10px] text-white text-[16px] sm:text-[20px] drop-shadow hover:drop-shadow-lg text-center hover:bg-custom-navBar focus:outline-none font-medium font-['DM Sans']"
          >
            <p> Save & Close </p>
          </button>
        </div>
      </div>

      <div className="mt-5 sm:mt-20 sm:px-2 md:px-40 p-2 sm:p-4 w-full">
        <h1 className="text-[20px] font-medium tracking-tight text-black ">
          Add images and call to action
        </h1>
      </div>

      <div className="h-auto sm:h-full md:h-[50%] sm:px-2 md:px-40 p-2 pb-4 sm:pb-0 md:p-4">
        <div className="grid h-[50%] sm:h-full grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-2 pt-2 flex flex-col justify-start items-start sm:items-end">
            <div className="p-3 w-full md:w-3/4 bg-white rounded-[17px] drop-shadow-md justify-center items-center inline-flex">
              <div className="w-[100%] min-h-full relative">
                <div className="flex flex-row justify-center md:space-x-4">
                  <div className="w-[125%] sm:w-full md:w-[65%]">
                    <h1 className="text-left text-[25px] text-black font-medium font-['Montserrat'] step-11">
                      Call To Action
                    </h1>
                    <p className="text-left pb-2 text-[16px] sm:text-[18px] text-black font-normal font-['Montserrat']">
                      Create a call to action...
                    </p>
                  </div>

                  <div className="w-full flex justify-end items-start mt-3 sm:w-[50%] md:w-[35%] max-w-[100%]">
                    {!isEnhancing ? (
                      <button
                        onClick={enhanceCallToAction}
                        // <button onClick={onEnhance}
                        className="w-[90%] sm:w-full px-2 py-2 bg-violet-100 hover:drop-shadow-md rounded-[10px] text-purple-500 text-[2vw] sm:text-[2vw] md:text-[1.07vw] font-medium font-['DM Sans']"
                      >
                        Enhance with AI
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="w-full flex justify-center py-2.5 px-5 me-2 text-sm font-medium text-purple-500 bg-violet-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-gray-200 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#9f7aea"
                          />
                        </svg>
                        Enhancing...
                      </button>
                    )}
                  </div>
                </div>

                <div className="w-full left-0 md:mt-1 p-1 bg-white rounded-xl border-2 border-purple-500 relative">
                  <textarea
                    ref={callToActionRef}
                    placeholder="Next Steps? Book a meeting..."
                    className={`w-full h-full pl-1 pb-18 text-[16px] text-black border-none outline-none align-text-top resize-none 
                                            ${
                                              callToActionOverflow
                                                ? "overflow-y-auto"
                                                : "overflow-y-hidden"
                                            }`}
                    value={formData.callToActionText || ""}
                    onChange={callToActionTextChange}
                  />
                  <div className="absolute bottom-0 right-0 p-2 text-sm text-gray-600">
                    {callToActionCharCount}/{callToActionCharLimit}
                  </div>
                </div>
                {ctaErrorMessage && (
                  <div className="text-red-500 mt-2">{ctaErrorMessage}</div>
                )}
              </div>
            </div>
          </div>

          <div className="md:pt-2 sm:pr-4 w-full sm:w-fit sm:max-w-[80%] sm:mt-1 md:mt-1 flex flex-col justify-start items-start">
            <button
              className="bg-white hover:drop-shadow-md ext-black drop-shadow py-2 px-4 rounded-lg"
              onClick={handleImageUpload}
            >
              Upload Images
            </button>
            <div className="mt-1">
              <button
                className="bg-white hover:drop-shadow-md ext-black drop-shadow py-2 px-4 rounded-lg"
                onClick={openModal}
              >
                Open Image Search
              </button>
            </div>
            <button
              className="bg-white hover:drop-shadow-md text-black drop-shadow py-2 px-4 rounded-lg mt-1"
              onClick={handleToggleModal}
            >
              AI Generated Images
            </button>
            <div className="bg-white hover:drop-shadow-md text-black drop-shadow py-2 px-4 rounded-lg mt-1">
              <button onClick={handleColourModal} className="app">
                Adjust Color
              </button>
              {isModalOpen && (
                <div className="modal-overlay fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                  <div className="modal-content">
                    <div className="flex flex-col">
                      {/* Row 1 */}
                      <div className="h-[30%] sm:h-[36%] p-4 bg-white rounded-lg shadow-md mb-4 flex flex-col justify-center">
                        <h2 className="text-2xl font-semibold text-gray-700 text-left ml-0 sm:ml-8 mb-4 brand-colors">
                          Select your colors below
                        </h2>
                        <div className="flex flex-col sm:flex-row justify-start items-start">
                          <div className="flex flex-col justify-evenly items-start pl-2 sm:pl-4 rounded-lg">
                            {" "}
                            {/* Adjusted padding-left */}
                            <div className="text-center relative mb-2">
                              {" "}
                              {/* Reduced margin-bottom */}
                              <input
                                type="color"
                                id="primaryColorInput"
                                value={primaryColor}
                                onChange={(event) =>
                                  handlePrimaryColorChange(
                                    event,
                                    updateFormData,
                                    setPrimaryColor,
                                  )
                                }
                                className="absolute w-6 h-6 opacity-0" // make it small and invisible
                                style={{
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  left: `${isMobile ? "180%" : "-90%"}`,
                                  marginLeft: "1rem",
                                }} // position it next to the color block
                              />
                              <div
                                className={`${
                                  isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                                } rounded-lg shadow-lg cursor-pointer`}
                                style={{ backgroundColor: primaryColor }}
                                onClick={() =>
                                  document
                                    .getElementById("primaryColorInput")
                                    ?.click()
                                }
                              >
                                {/* The color block */}
                              </div>
                              <p className="mt-2 text-sm font-medium text-gray-600">
                                Primary
                              </p>
                            </div>
                            <div className="text-center relative mb-2">
                              {" "}
                              {/* Reduced margin-bottom */}
                              <input
                                type="color"
                                id="secondaryColorInput"
                                value={secondaryColor}
                                onChange={(event) =>
                                  handleSecondaryColorChange(
                                    event,
                                    updateFormData,
                                    setSecondaryColor,
                                  )
                                }
                                className="absolute w-6 h-6 opacity-0" // make it small and invisible
                                style={{
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  left: `${isMobile ? "130%" : "-90%"}`,
                                  marginLeft: "1rem",
                                }} // position it next to the color block
                              />
                              <div
                                className={`${
                                  isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                                } rounded-lg shadow-lg cursor-pointer`}
                                style={{ backgroundColor: secondaryColor }}
                                onClick={() =>
                                  document
                                    .getElementById("secondaryColorInput")
                                    ?.click()
                                }
                              >
                                {/* The color block */}
                              </div>
                              <p className="mt-2 text-sm font-medium text-gray-600">
                                Secondary
                              </p>
                            </div>
                            <div className="text-center relative mb-2">
                              {" "}
                              {/* Reduced margin-bottom */}
                              <input
                                type="color"
                                id="tertiaryColorInput"
                                value={tertiaryColor}
                                onChange={(event) =>
                                  handleTertiaryColorChange(
                                    event,
                                    updateFormData,
                                    setTertiaryColor,
                                  )
                                }
                                className="absolute w-6 h-6 opacity-0" // Make the color input invisible and position it next to the color block
                                style={{
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  left: `${isMobile ? "82%" : "-90%"}`,
                                  marginLeft: "1rem",
                                }} // Adjust position as needed
                              />
                              <div
                                className={`${
                                  isSmallMobile ? "w-20 h-20" : "w-24 h-24"
                                } rounded-lg shadow-lg cursor-pointer`}
                                style={{ backgroundColor: tertiaryColor }}
                                onClick={() =>
                                  document
                                    .getElementById("tertiaryColorInput")
                                    ?.click()
                                }
                              >
                                {/* The color block */}
                              </div>
                              <p className="mt-2 text-sm font-medium text-gray-600">
                                Tertiary
                              </p>
                            </div>
                          </div>
                          <div className="flex-grow ml-2 sm:ml-4">
                            {" "}
                            {/* Added flex-grow to make it flexible */}
                            <Canvas />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={onColourSave}
                      className="bg-blue-500 text-white hover:bg-blue-600 drop-shadow py-2 px-4 rounded-lg"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleColourModal}
                      className="bg-white hover:drop-shadow-md text-black drop-shadow py-2 px-4 rounded-lg"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              id="imageUpload"
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple // Allow multiple file selection
            />
            {images.length > 0 && (
              <div
                className="max-h-[62%] w-[105%] sm:w-full rounded-xl drop-shadow-md overflow-auto bg-white p-4 mt-4"
                ref={containerRef}
              >
                {images.map((image, index) => (
                  <div key={index} className="mb-2 flex items-center">
                    <img
                      src={image.url}
                      alt={image.name}
                      className="h-8 w-8 rounded mr-2"
                    />
                    {image.name}
                    <button
                      className="ml-2 text-red-500 hover:text-red-700"
                      onClick={() => handleRemoveImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="md:pr-[20%] sm:pr-[30%] pt-4">
              <div className="flex w-full justify-center sm:justify-end">
                <button
                  onClick={handleGenerate}
                  className="rounded-lg bg-purple-500 px-10 py-3 text-white transition hover:bg-purple-600 step-12"
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col bg-white drop-shadow-md h-[30%] w-[80%] sm:w-[40%] justify-center items-center rounded-xl loading-indicator">
            <div role="status" className="mt-2 relative">
              <svg
                aria-hidden="true"
                className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <div className="absolute inset-0 flex justify-center items-center">
                <img
                  src="../../../images/loadingLogo.png"
                  alt="Company Logo"
                  className="w-12 h-12"
                />
              </div>
            </div>

            <div
              className="text-[18px] transition-opacity duration-500 ease-linear mt-3"
              style={{ opacity: fade ? 1 : 0 }}
            >
              {currentMessage}
            </div>
          </div>
        </div>
      )}
      {preview && (
        <div className="fixed inset-0 flex justify-center items-center z-40">
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative w-[50%] bg-white p-8 flex flex-col rounded-lg max-h-[90%] max-w-[90%] sm:max-w-[90%] lg:max-w-[80%] xl:max-w-[70%]">
              <div className="flex justify-between w-full">
                <h1 className="text-2xl font-bold">
                  {generatedImages.length > 0
                    ? "Select Your Image(s)"
                    : "Generate Your Image"}
                </h1>
                <button
                  className="text-gray-500"
                  onClick={handleToggleModal}
                  style={{ fontSize: "1.5rem", marginTop: "-0.25rem" }}
                >
                  &times;
                </button>
              </div>

              {generatedImages.length === 0 ? (
                <>
                  <div className="flex items-center">
                    <p className="mr-2 mt-2 mb-2">Select image output</p>
                    <button
                      className={`m-2 p-2 rounded-lg flex items-center justify-center w-20 h-8 ${
                        selectedButton === "DALL_E"
                          ? "outline outline-2 outline-purple-500 bg-black text-white"
                          : "bg-black text-white"
                      }`}
                      onClick={() => handleButtonClick("DALL_E")}
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src="../../../images/dalle-logo.png"
                        alt="DALL-E"
                        style={{
                          width: "250%",
                          height: "250%",
                          objectFit: "cover",
                        }}
                      />
                    </button>
                    <button
                      className={`m-2 p-2 rounded-lg flex items-center justify-center w-20 h-8 ${
                        selectedButton === "Gemini"
                          ? "outline outline-2 outline-purple-500"
                          : "bg-white text-purple-400 border border-gray-200"
                      }`}
                      onClick={() => handleButtonClick("Gemini")}
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src="../../../images/gemini-logo.png"
                        alt="GEMINI"
                        style={{
                          width: "250%",
                          height: "250%",
                          objectFit: "cover",
                        }}
                      />
                    </button>
                    <button
                      className={`m-2 p-2 rounded-lg flex items-center justify-center w-20 h-8 ${
                        selectedButton === "Midjourney"
                          ? "outline outline-2 outline-purple-500 bg-gray-200"
                          : "bg-gray-200 text-black"
                      }`}
                      onClick={() => handleButtonClick("Midjourney")}
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src="../../../images/midjourney-logo.png"
                        alt="MIDJOURNEY"
                        style={{
                          width: "250%",
                          height: "250%",
                          objectFit: "cover",
                          transform: "translateX(-5%)",
                        }}
                      />
                    </button>
                  </div>

                  {selectedButton && (
                    <div className="mt-2 mb-2">
                      <p className="text-gray-700 text-sm">
                        {descriptions[selectedButton]}
                      </p>
                    </div>
                  )}

                  <div className="flex flex-col w-full">
                    {comingSoonMessage ? (
                      <p className="mt-2 text-center text-xl text-purple-600">
                        {comingSoonMessage}
                      </p>
                    ) : (
                      <textarea
                        id="prompt-textarea"
                        style={{
                          border: "3px solid #A855F7",
                          outline: "none",
                        }}
                        className={`mt-2 resize-none overflow-hidden rounded-xl px-2 py-1 w-full h-28 mb-4 ${
                          isPromptDisabled ? "invisible" : ""
                        }`}
                        placeholder="Enter your prompt here"
                        value={aiPrompt}
                        onChange={(e) => setAiPrompt(e.target.value)}
                        disabled={isPromptDisabled}
                      />
                    )}

                    <div className="flex justify-end w-full">
                      {!isGenerateDisabled && (
                        <button
                          className="bg-custom-select text-white px-6 py-3 rounded mr-2"
                          onClick={handleGenerateImages}
                        >
                          Generate Image
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-col w-full">
                  <div className="flex justify-center items-center mb-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 p-4">
                      {generatedImages.map((image, index) => (
                        <div
                          key={index}
                          className={`relative ${
                            selectedImages.includes(`image_${index}`)
                              ? "outline outline-4 outline-[#A569FF] rounded"
                              : "rounded"
                          }`}
                          onClick={() =>
                            handleSelectImage(
                              index,
                              !selectedImages.includes(`image_${index}`),
                            )
                          }
                        >
                          {image ? (
                            <img
                              src={image}
                              alt={`Generated ${index + 1}`}
                              className="w-80 h-80 border-2 border-gray-300 rounded flex justify-center items-center" // Make images bigger
                            />
                          ) : (
                            <div className="w-80 h-80 border-2 border-gray-300 rounded flex justify-center items-center">
                              {" "}
                              <p className="text-gray-400">Image {index + 1}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-end w-full mt-4">
                    <button
                      className="bg-gray-500 text-white px-6 py-3 rounded mr-2"
                      onClick={handleBackButtonClick}
                    >
                      Back
                    </button>
                    <button
                      className="bg-custom-select text-white px-6 py-3 rounded"
                      onClick={handleUseSelectedImages}
                    >
                      Use Image
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {modalIsOpen && (
        <div className="flex justify-center items-center inset-0 fixed bg-black bg-opacity-75">
          <div className="flex justify-center items-center inset-0 fixed">
            <div
              className="bg-white p-6 shadow-lg"
              style={{ width: `${modalWidth}px`, height: `${modalHeight}px` }}
            >
              <div className="flex flex-col">
                <button
                  onClick={closeModal}
                  className="self-end text-xl font-semibold text-purple-600 focus:outline-none w-4 h-4 p-0 m-0 mt-[-1rem] mr-[-8px]"
                >
                  x
                </button>
              </div>
              <div className="max-w-md mx-auto">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Images..."
                  />
                  <button
                    type="submit"
                    className="text-white absolute end-2.5 bottom-2.5 bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                    onClick={() => handleSearch(1)}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="p-4 mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {photos.map((photo) => (
                  <div
                    key={photo.id}
                    className={`relative group cursor-pointer border-4 ${
                      selectedImages.includes(`image_${photo.id}`)
                        ? "border-purple-700 rounded-xl"
                        : "border-transparent"
                    }`}
                    onClick={() =>
                      handleSelectImage(
                        photo.id,
                        !selectedImages.includes(`image_${photo.id}`),
                      )
                    }
                  >
                    <img
                      src={photo.src.tiny}
                      alt={photo.alt}
                      className="w-full h-auto object-cover rounded-lg shadow-md"
                    />
                    <div className="absolute inset-0 bg-black rounded-lg bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white text-lg p-3">
                        {photo.alt}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-4">
                {showButton && (
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="bg-purple-600 w-24 text-white px-4 py-2 rounded-lg disabled:bg-gray-400"
                  >
                    Previous
                  </button>
                )}
                {showButton && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="bg-purple-600 w-24 text-white px-4 py-2 rounded-lg disabled:bg-gray-400"
                  >
                    Next
                  </button>
                )}
              </div>
              {!showButton && (
                <div className="flex justify-center items-center m-4">
                  <h3 className="italic">
                    Please search for an image and a gallery of images will
                    show.
                  </h3>
                </div>
              )}
              {showButton && (
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleUseSelectedImagesforStock}
                    className="bg-purple-600 text-white px-4 py-2 rounded-lg"
                  >
                    Add Image
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workflow3;
