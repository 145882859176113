const generateAIImages = async (
  prompt: string,
  updateImageUrls: (imageUrls: string[]) => void,
  setLoading: (isLoading: boolean) => void,
  onCompletion: () => void,
  numRequests: number = 4, // number of requests to generate images
): Promise<void> => {
  try {
    setLoading(true);
    const fetchImage = async () => {
      const url = "https://api.openai.com/v1/images/generations";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt,
          n: 1, // number of images to generate per request
          size: "1024x1024",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Response data:", data); // Log the entire response data
      return data.data.map((item: { url: string }) => item.url);
    };
    const requests = Array.from({ length: numRequests }, fetchImage);
    const results = await Promise.all(requests);
    const imageUrls = results.flat();
    updateImageUrls(imageUrls);
    console.log("Image URLs:", imageUrls); // Log the extracted image URLs
  } catch (error) {
    console.error("Error generating images:", error);
  } finally {
    setLoading(false);
    onCompletion();
  }
};
export default generateAIImages;
