import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "../../Images/Common/DragAndDropIcon.png"; // Import your UploadIcon component

const DragAndDrop: React.FC<{
  onFilesUploaded: (files: File[]) => void;
  isMobile: boolean;
}> = ({ onFilesUploaded, isMobile }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Handle the files
      onFilesUploaded(acceptedFiles); // Call the callback function with the files
    },
    [onFilesUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true, // Allow multiple files
  });

  return (
    <div
      {...getRootProps()}
      className="max-h-[100%] flex justify-center items-center sm:h-full p-4 border-2 border-dashed border-gray-300 rounded-lg space-y-2"
    >
      <input {...getInputProps()} />
      <div className="text-center">
        <div className="text-gray-500 mb-2">
          <div className="flex justify-center">
            <img src={UploadIcon} alt="Custom Logo" className="size-10" />
          </div>
          <p>{isMobile ? "Upload Files" : "Drag & Drop Logo"}</p>
          <p className="text-xs text-gray-400">
            {isMobile
              ? "click to upload any branding materials from mobile"
              : "any branding materials or select files from device"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
