const getProject = async (token: any) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Project`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      },
    );
    /* if (response.status === 403) {
      window.location.href = "https://buy.stripe.com/9AQeWxcTyb1ncY8145";
      return null;
    } */

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // use FE Logger when set up
    // console.log('Project Retrieved Successfully:', projectData);
    return await response.json();
  } catch (error) {
    console.error("Error retrieving project data:", error);
    return null;
  }
};

export default getProject;
