import React, { createContext, useContext, useState } from "react";
import { DefaultProps } from "../Utility/Variables/Interfaces";

const NavBarContext = createContext({
  isSidebarExpanded: true, // Default state
  toggleSidebar: () => {}, // Placeholder for the toggle function
});

export const useNavbarContext = () => useContext(NavBarContext);

export const NavBarProvider: React.FC<DefaultProps> = ({ children }) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  // Using a functional update to ensure we always get the latest state
  const toggleSidebar = () => {
    setIsSidebarExpanded((prevState) => !prevState); // Automatically get the most recent state
  };

  // Pass the state and the toggle function to the context
  return (
    <NavBarContext.Provider value={{ isSidebarExpanded, toggleSidebar }}>
      {children}
    </NavBarContext.Provider>
  );
};
