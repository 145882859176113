const removeBackground = async (imageFile: File) => {
  const formData = new FormData();
  formData.append("imageFile", imageFile);

  const url = `${process.env.REACT_APP_SELLSTATICBE_URL}/imageprocessing/removebackground`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorBody = await response.json();
      console.log("Error body:", errorBody);
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${
          errorBody.message || "No message provided"
        }`,
      );
    }

    return await response.blob();
  } catch (error) {
    console.error("Error processing image:", error);
    throw error;
  }
};

export default removeBackground;
