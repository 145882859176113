import React from "react";
import { useNavigate } from "react-router-dom";
import { LocalProject } from "../../Utility/Variables/Types";
import { useFormContext } from "../../Context/FormContext";
import { useProjectContext } from "../../Context/ProjectsContext";

import LoadProject from "../../Utility/Functions/LoadProject";
import ResetProjectData from "../../Utility/Functions/ResetProjectData";
import GenerateProjectId from "../../Utility/Functions/GenerateProjectId";

const Marketing: React.FC = () => {
  const { projects } = useProjectContext();
  const { updateFormData } = useFormContext();
  const itemsArray = Object.values(projects);
  const navigate = useNavigate();

  const handleNewProject = () => {
    GenerateProjectId(updateFormData);
    ResetProjectData(updateFormData);
    navigate("/Workflow_1", { state: { newProject: true } });
  };

  const handleProject = (project: LocalProject) => {
    LoadProject(updateFormData, project);

    // If images are generated, direct them straight to workflow_4
    if (project.campaign.generationImages) {
      navigate("/Workflow_4", {
        state: { newProject: false, projectId: project.id },
      });
    } else {
      navigate("/Workflow_1", { state: { newProject: false } });
    }
  };

  return (
    <div className="w-full h-full flex flex-col px-5 pb-5 sm:pb-0 overflow-auto">
      <div className="grid grid-cols-1 gap-4 mb-10 md:space-x-4">
        <div className="flex flex-col ml-2 w-full">
          <h1 className="sm:h-11 relative mt-4 text-black text-[30px] font-medium font-['Montserrat'] step-1">
            Automated Marketing
          </h1>
          <p className="text-black text-[18px] tracking-tight w-full mt-3.5">
            Create new ad campaign and automate marketing
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 auto-rows-[325px] sm:grid-cols-2 md:grid-cols-4 gap-4 px-5">
        <button onClick={handleNewProject}>
          <div className="h-full shadow-sm bg-white flex flex-col items-stretch px-4 sm:px-8 md:px-14 py-12 rounded-2xl border-2 border-solid border-slate-500 cursor-pointer hover:shadow-lg transition">
            <header className="justify-center text-neutral-500 text-center text-xl font-medium leading-8 w-full mt-12 step-2">
              Create New <br /> Ad Campaign
            </header>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c0acc94be8a2a0207ac644c7470f9c706b2335c29d774c7ced1c7a2880675ce?apiKey=b7ba3d8a1feb442a96b5eac4ad3f4826&"
              className="aspect-[0.9] object-contain object-center w-[18px] fill-neutral-500 overflow-hidden self-center max-w-full mt-6 mb-11"
              alt="Ad Campaign"
            />
          </div>
        </button>

        {itemsArray.map((project) => (
          <button
            className="h-full w-full"
            onClick={() => handleProject(project)}
            key={project.id} // Assuming each project has a unique 'id'
          >
            <div className="h-full shadow-sm bg-white flex flex-col justify-center items-center px-4 sm:px-8 md:px-20 py-12 rounded-2xl border-2 border-solid border-slate-500 cursor-pointer hover:shadow-lg transition">
              <header className="justify-center text-neutral-500 text-center text-xl font-medium leading-8 w-full">
                {project.name}
              </header>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Marketing;
