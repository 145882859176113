import React, { useEffect, useState, lazy, Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { TourProvider } from "@reactour/tour";

import { GlobalProject, LocalProject } from "./Utility/Variables/Types";
import { useLogin } from "./Context/LoginContext";
import { FormProvider } from "./Context/FormContext";
import { NavBarProvider } from "./Context/NavBarContext";
import { useProjectContext } from "./Context/ProjectsContext";

import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import PopulateProjects from "./Utility/Functions/PopulateProjects";

import Profile from "./Routes/Profile/Profile";
import Marketing from "./Routes/Marketing/Marketing";
import SellStaticAI from "./Routes/SellStaticAI/SellStaticAI";
import WorkFlowOne from "./Routes/Marketing/Workflow_1";
import WorkFlowTwo from "./Routes/Marketing/Workflow_2";
import WorkFlowThree from "./Routes/Marketing/Workflow_3";
import WorkFlowFour from "./Routes/Marketing/Workflow_4";
import GetProjects from "./Services/Project/GET/GetProjects";
import PaymentSuccess from "./Components/stripe/paymentsucess";
import VerifySubscription from "./Components/stripe/verifySubscription";
import PaymentFailure from "./Components/stripe/paymentfailure";
import steps from "./TourSteps";

// Lazy load the Dashboard component
const Dashboard = lazy(() => import("./Routes/Dashboard/Dashboard"));

function App() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { login } = useLogin();
  const { addProject } = useProjectContext();

  const initializeLogin = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    try {
      await login(token);
      console.log(`TRIAL ${process.env.REACT_APP_SELLSTATICBE_URL}`);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const initializeProjects = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${process.env.REACT_APP_SELLSTATIC_AUDIANCE}`,
      },
    });

    try {
      const projects = await GetProjects(token);

      projects.forEach((project: GlobalProject) => {
        const projectToAdd: LocalProject = PopulateProjects(project);
        addProject(projectToAdd);
      });
    } catch (error) {
      console.error("Error on getting projects", error);
    }
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // User is not authenticated, initiate login
      loginWithRedirect().then(() => {
        /* Use FE Logger ('User Redirected to log in') when set up */
      });
    } else if (!isLoading && isAuthenticated) {
      initializeLogin().then(() => {
        /* Use FE Logger ('User Logged in') when set up */
      });
      initializeProjects().then(() => {
        /* Use FE Logger ('Projects Initialized') when set up */
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  const redirect = useNavigate();
  const [step, setStep] = useState<number>(0);

  const setCurrentStep = (value: number | ((prevStep: number) => number)) => {
    const newStep = typeof value === "function" ? value(step) : value;
    switch (true) {
      case newStep <= 8:
        redirect("/profile", { replace: true });
        break;
      case newStep <= 10:
        redirect("/marketing", { replace: true });
        break;
      case newStep <= 13:
        redirect("/workflow_1", { replace: true });
        break;
      case newStep <= 18:
        redirect("/workflow_2", { replace: true });
        break;
      case newStep <= 20:
        redirect("/workflow_3", { replace: true });
        break;
      case newStep <= 24:
        redirect("/workflow_4", { replace: true });
        break;
      case newStep <= 26:
        redirect("/sellstatic-ai", { replace: true });
        break;
      default:
        break;
    }
    setStep(newStep);
  };

  return (
    <TourProvider
      steps={steps}
      currentStep={step}
      setCurrentStep={setCurrentStep}
    >
      <FormProvider>
        <NavBarProvider>
          <div className="flex flex-col m-2 sm:flex-row sm:mr-0 sm:mb-0">
            <div className="flex mb-2 sm:mb-0">
              <NavBar />
            </div>
            <div
              className="flex flex-grow overflow-x-hidden justify-start h-[91vh] sm:h-[98vh] rounded-[16px] bg-gray-200 transition-all duration-300 right-0
                                    sm:mr-2 sm:ml-2"
            >
              <Suspense
                fallback={
                  <div className="flex items-center justify-center h-full">
                    <div className="text-center">
                      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-indigo-600" />
                      <p className="mt-4 text-lg font-medium text-gray-600">
                        Loading...
                      </p>
                    </div>
                  </div>
                }
              >
                <Routes>
                  <Route
                    path="/verifysubscription"
                    element={<VerifySubscription />}
                  />
                  <Route path="/" element={<PaymentSuccess />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/paymentfailure" element={<PaymentFailure />} />
                  <Route path="/marketing" element={<Marketing />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/sellstatic-ai" element={<SellStaticAI />} />
                  <Route path="/workflow_1" element={<WorkFlowOne />} />
                  <Route path="/workflow_2" element={<WorkFlowTwo />} />
                  <Route path="/workflow_3" element={<WorkFlowThree />} />
                  <Route path="/workflow_4" element={<WorkFlowFour />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </NavBarProvider>
      </FormProvider>
    </TourProvider>
  );
}

export default App;
