const generateImages = async (
  projectId: string,
  token: string,
): Promise<any | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/Project/${projectId}/generateImages`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      },
    );

    if (!response.ok) {
      const errorBody = await response.json();
      const errorMessage = `HTTP error! Status: ${response.status}, Message: ${errorBody.message}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data.project;
  } catch (error) {
    console.error("Error generating images:", error);
    return null;
  }
};

export default generateImages;
