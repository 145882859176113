import { GlobalProject } from "../Variables/Types";

const UpdateProjectImages = (
  project: GlobalProject,
  updateProject: (key: string, updatedProjectImages: string[]) => void,
) => {
  // FE Project key must be name because that is the identifier used for projects
  const key = project.id;
  const generatedImages: string[] = [];

  // To generate a string[] of URL's to display
  project.campaign.generationImages.forEach((generationImage) => {
    generatedImages.push(generationImage.imageUrl);
  });

  // Should replace with formData.generatedImages eventually
  updateProject(key, generatedImages);
};

export default UpdateProjectImages;
