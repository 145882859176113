import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useNavbarContext } from "../../Context/NavBarContext";

import "./NavBar.css";
import logo_expanded from "../../Images/NavBar/SellStaticLogoExpanded.png";
import logo_collapsed from "../../Images/NavBar/SellStaticLogoCollapsed.png";
import profile_icon from "../../Images/NavBar/ProfileIcon.png";
import profile_icon_selected from "../../Images/NavBar/ProfileIconSelected.png";
import marketing_icon from "../../Images/NavBar/MarketingIcon.png";
import marketing_icon_selected from "../../Images/NavBar/MarketingIconSelected.png";
import sellStaticAI_icon from "../../Images/NavBar/SellStaticAIIcon.png";
import sellStaticAI_icon_selected from "../../Images/NavBar/SellStaticAIIconSelected.png";
import dashboard_icon from "../../Images/NavBar/DashboardIcon.png";
import dashboard_icon_selected from "../../Images/NavBar/DashboardIconSelected.png";

function NavBar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isFooterCollapsed] = useState(false);
  const [profile, profileH] = useState(false);
  const [marketing, marketingH] = useState(false);
  const [dashboard, dashboardH] = useState(false);
  const [sellStaticAI, sellStaticAIH] = useState(false);
  // const [settings, settingsH] = useState(false);
  const { toggleSidebar } = useNavbarContext();
  const { loginWithRedirect } = useAuth0();
  // For mobile trigger
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  const profileSrc = profile ? profile_icon_selected : profile_icon;
  const marketingSrc = marketing ? marketing_icon_selected : marketing_icon;
  const dashboardSrc = dashboard ? dashboard_icon_selected : dashboard_icon;
  const sellStaticAISrc = sellStaticAI
    ? sellStaticAI_icon_selected
    : sellStaticAI_icon;
  // const settingsSrc = settings ? settings_icon_selected : settings_icon;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    toggleSidebar();
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    const sidebarElement = document.getElementById("nav-bar");
    if (sidebarElement) {
      sidebarElement.addEventListener("transitionend", toggleSidebar);
    }

    return () => {
      if (sidebarElement) {
        sidebarElement.removeEventListener("transitionend", toggleSidebar);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="nab-bar-container" className="w-full">
      <div
        id="nav-bar"
        className={isCollapsed && !isMobile ? "collapsed" : ""}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div id="nav-header">
          <Link to="/" id="nav-title">
            <img src={logo_expanded} alt="SELLSTATIC" width="195" height="50" />
          </Link>

          <div id="nav-toggle" onClick={handleToggleSidebar}>
            <img
              className={`nav-toggle-img ${isMobile ? "hidden" : "visible"}`}
              src={logo_collapsed}
              alt="HOME"
              width="50"
              height="50"
            />
          </div>
          <hr />
        </div>
        <div id="nav-content">
          <Link
            to="/profile"
            className="order-2 sm:order-1 nav-button"
            onMouseEnter={() => profileH(true)}
            onMouseLeave={() => profileH(false)}
          >
            <img src={profileSrc} alt="DF" width="28" height="28" />
            <span className="ml-4">Profile</span>
          </Link>
          <Link
            to="/dashboard"
            className="order-2 sm:order-2 nav-button"
            onMouseEnter={() => dashboardH(true)}
            onMouseLeave={() => dashboardH(false)}
          >
            <img src={dashboardSrc} alt="DF" width="28" height="28" />
            <span className="ml-4">Dashboard</span>
          </Link>
          <Link
            to="/marketing"
            className="order-3 sm:order-3 nav-button"
            onMouseEnter={() => marketingH(true)}
            onMouseLeave={() => marketingH(false)}
          >
            <img src={marketingSrc} alt="CF" width="28" height="28" />
            <span className="ml-4">Marketing</span>
          </Link>
          <Link
            to="/sellstatic-ai"
            className="order-4 sm:order-4 nav-button"
            onMouseEnter={() => sellStaticAIH(true)}
            onMouseLeave={() => sellStaticAIH(false)}
          >
            <img src={sellStaticAISrc} alt="CF" width="28" height="28" />
            <span className="ml-4">SellStatic AI</span>
          </Link>
          <Link to="/profile" className="order-1 ml-2 sm:hidden sm:order-6">
            <img src={logo_collapsed} alt="SELLSTATIC" width="45" height="45" />
          </Link>
          <div id="nav-content-highlight" />
        </div>
        <div
          id="nav-footer"
          onClick={() => loginWithRedirect()}
          className={`${
            isFooterCollapsed ? "collapsed" : ""
          } flex justify-center items-center hover:cursor-pointer`}
        >
          <div id="nav-footer-content">Logout</div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
