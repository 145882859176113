const PostImages = async (
  projectId: string,
  images: FileList | null,
  token: string,
  type: string,
): Promise<string | null> => {
  try {
    if (!images || images.length === 0) {
      return null; // No images to upload, return early
    }

    const formData = new FormData();
    formData.append("ProjectId", projectId);
    formData.append("Type", type);
    for (let i = 0; i < images.length; i++) {
      formData.append("Images", images[i]);
    }

    const response = await fetch(
      `${process.env.REACT_APP_SELLSTATICBE_URL}/s3`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: formData,
      },
    );

    if (!response.ok) {
      const errorBody = await response.json();
      const errorMessage = `HTTP error! Status: ${response.status}, Message: ${errorBody.message}`;
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    // use FE Logger when set up
    // console.error('Error posting project data:', error);
    return null;
  }
};

export default PostImages;
